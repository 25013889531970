var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Products")]),
      _vm._v(" "),
      _c("card-grid", [
        _c("div", { staticClass: "card" }, [
          _c("h2", [_vm._v("New Product")]),
          _vm._v(" "),
          _c("p", [
            _c("span", [
              _vm._v("Add a new purchasable product to the web billing system"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "category" } }, [_vm._v("Type")]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.type,
                          expression: "product.type",
                        },
                      ],
                      attrs: { id: "type", name: "type", required: "" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.product,
                            "type",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.categories, function (category) {
                      return _c(
                        "option",
                        { key: category, domProps: { value: category } },
                        [_vm._v(_vm._s(category))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "product" } }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.product.name,
                        expression: "product.name",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "product",
                      name: "product",
                      placeholder: "Product name",
                      required: "",
                    },
                    domProps: { value: _vm.product.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.product, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "sku" } }, [_vm._v("SKU")]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.product.sku,
                        expression: "product.sku",
                      },
                    ],
                    attrs: {
                      type: "text",
                      id: "sku",
                      name: "sku",
                      placeholder: "Unique product identifier",
                      required: "",
                    },
                    domProps: { value: _vm.product.sku },
                    on: {
                      change: _vm.checkSku,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.product, "sku", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "price" } }, [_vm._v("Price")]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.product.price,
                        expression: "product.price",
                      },
                    ],
                    attrs: {
                      type: "number",
                      id: "price",
                      name: "price",
                      placeholder: "default price",
                      min: "0.00",
                      max: "100000.00",
                      step: "0.01",
                      required: "",
                    },
                    domProps: { value: _vm.product.price },
                    on: {
                      change: _vm.checkPrice,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.product, "price", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.product.type === "Subscription"
                    ? _c("span", [_vm._v(" / year")])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "button",
                { attrs: { type: "submit", disabled: _vm.processing } },
                [_c("span", [_vm._v("Add " + _vm._s(_vm.product.sku))])]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.added.length > 0
            ? _c(
                "div",
                [
                  _c("h3", [_vm._v("Recently history")]),
                  _vm._v(" "),
                  _vm._l(_vm.added, function (product) {
                    return _c(
                      "div",
                      { key: product?.metadata?.sku || product?.name },
                      [
                        !product.error
                          ? _c("div", [
                              _c(
                                "a",
                                { attrs: { href: _vm.productUrl(product) } },
                                [
                                  _c("b", [_vm._v(_vm._s(product.name))]),
                                  _vm._v(
                                    ": " + _vm._s(_vm.productUrl(product))
                                  ),
                                ]
                              ),
                            ])
                          : _c("div", [
                              _c("i", [
                                _vm._v("Error: " + _vm._s(product.error)),
                              ]),
                            ]),
                        _vm._v(" "),
                        _c("json-viewer", {
                          attrs: { value: product, "expand-depth": 0 },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("SKUs")]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "All product SKUs found in the catalog. SKU properties are extracted from Realtime database product metadata /product/metadata (original source is the set of metadata.json files). Enables transation processing (to lookup province code) and product catalog organization."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("b", [_vm._v("/product/skus/")]),
            _vm._v(" "),
            _c("json-viewer", {
              attrs: { value: _vm.skus, "expand-depth": 0, sort: true },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Prices")]),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v(
                  "All product prices found in the catalog. Product prices are extracted from Firestore database product prices /products/{products}/prices/{prices}. Cache of default product prices."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("b", [_vm._v("/product/prices/")]),
            _vm._v(" "),
            _c("json-viewer", {
              attrs: { value: _vm.prices, "expand-depth": 0, sort: true },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }