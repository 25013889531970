var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ul",
        { staticClass: "header" },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "li",
            {
              key: tab.title,
              class: { selected: index == _vm.selected },
              on: {
                click: function ($event) {
                  return _vm.selectTab(index)
                },
              },
            },
            [_vm._v("\n            " + _vm._s(tab.title) + "\n        ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }