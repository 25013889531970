<style lang="scss" scoped>


</style>

<template>
    <div>
        <h1>Status Monitor</h1>

        <card-grid>
            <div class="card">
                <h2>Tiles Service  - Counties</h2>
                <cert-probe :url="tileServer"></cert-probe>
                <h3>Alberta</h3>
                <service-probe id="tilesAb" :url="tilesAbCountiesUrl" :proxy="true"></service-probe>
                <h3>Saskatchewan</h3>
                <service-probe id="tilesSk" :url="tilesSkCountiesUrl" :proxy="true"></service-probe>
                <h3>Manitoba</h3>
                <service-probe id="tilesMb" :url="tilesMbCountiesUrl" :proxy="true"></service-probe>
            </div>

            <div class="card">
                <h2>iOS App</h2>
                <service-probe id="ios" :url="iosUrl" :proxy="true" @response="response" :properties="iosProps" :data="iosData"></service-probe>
            </div>

            <div class="card">
                <h2>Android App</h2>
                <span><em>Not available</em></span>
            </div>

            <div class="card">
                <h2>Store</h2>
                <cert-probe :url="storeUrl"></cert-probe>
                <service-probe id="store" :url="storeUrl + '/manifest.json'" @response="response"  :properties="storeProps"></service-probe>
            </div>

            <div class="card">
                <h2>Dashboard</h2>
                <cert-probe :url="dashboardUrl"></cert-probe>
                <service-probe id="dashboard" :url="dashboardUrl + '/manifest.json'" @response="response" :properties="dashboardProps"></service-probe>
            </div>

            <div class="card">
                <h2>Web App</h2>
                <cert-probe :url="appUrl"></cert-probe>
                <service-probe id="web" :url="appUrl + '/manifest.json'" @response="response" :properties="webProps"></service-probe>
            </div>

            <div class="card">
                <h2>Functions</h2>
                <service-probe id="functions" :url="functionUrl + '/info/status'"></service-probe>
            </div>
        </card-grid>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import CardGrid from './CardGrid.vue';

    @Component
    export default class StatusPage extends CardGrid {

        webProps = [];
        dashboardProps = [];
        storeProps = [];
        iosProps = [];
        iosData = {};

        get iosUrl() {
            return 'https://itunes.apple.com/lookup?bundleId=com.kindevelopment.ihunteralberta';
        }

        get dashboardUrl() {
            return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }

        get tileServer() {
            //return 'https://expired.badssl.com'; // Testing
            return 'https://tiles.ihunterapp.com';
        }

        get tilesAbCountiesUrl() {
            return this.tileServer + '/tiles/ab/counties.json';
        }

        get tilesSkCountiesUrl() {
            return this.tileServer + '/tiles/sk/counties.json';
        }

        get tilesMbCountiesUrl() {
            return this.tileServer + '/tiles/mb/counties.json';
        }

        response(id, response) {
            let props = [];

            if(id === 'ios') {
                // ios doesn't mark response content-type, so we need to manually convert to json
                if(!response.resultCount) {
                    return;
                }

                let info = response.results[0];
                this.iosData = info;

                props.push({
                    name: "Version",
                    value: info.version
                });
                props.push({
                    name: "Release date",
                    value: info.currentVersionReleaseDate
                });

            }else {
                props.push({
                    name: "Version",
                    value: response.version
                });
                props.push({
                    name: "Release date",
                    value: response.date
                });
                props.push({
                    name: "Release time",
                    value: response.time
                });
                props.push({
                    name: "Developer",
                    value: response.dev
                });
            }

            switch(id) {
                case 'ios':
                    this.iosProps = props;
                    break;
                case 'web':
                    this.webProps = props;
                    break;
                case 'dashboard':
                    this.dashboardProps = props;
                    break;
                case 'store':
                    this.storeProps = props;
                    break;
            }
        }
    }

    Vue.component('status-page', StatusPage);

</script>

