var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-dots" }, [
    _c("div", { staticClass: "stage" }, [
      _vm.$slots.default ? _c("h2", [_vm._t("default")], 2) : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dot-bricks" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }