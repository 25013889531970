var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Affiliate Users")]),
      _vm._v(" "),
      _c("card-grid", [
        _c("div", { staticClass: "card" }, [
          _c("img", { attrs: { src: "https://i.imgur.com/fuv6iyl.gif" } }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }