var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-button" }, [
    _c("input", {
      attrs: {
        type: "text",
        placeholder: _vm.placeholder,
        maxlength: _vm.maxlength,
      },
      domProps: { value: _vm.content },
      on: { input: _vm.onInputChanged },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        attrs: { disabled: this.value == "" },
        on: { click: _vm.onButtonClick },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }