<style lang="scss" scoped>
    @import './dashboard.scss';

    a, .list-user-link {

        &.active {
            padding: 5px 8px 3px 8px;
            background: $menu-colour;
            color: white;
            font-weight: bold;
            border-radius: $menu-radius $menu-radius 0 0;
        }
    }

    .status::after {
        content: '';
        display: inline-block;
        box-sizing: border-box;
        background-color: $validation-bad;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        border: solid 0.5px $mono-dark;
        margin: 2px;
    }

    .status {
        &.enabled::after {
            background-color: $validation-good;
        }       
    }
</style>

<template>
    <div>
        <header>
            <h1>API Accounts</h1>
            <input-button v-model="input" :maxlength="64" @click="newAccount(input)" placeholder="New account name">Create</input-button>
        </header>
        <error-notice v-if="error" :error="error"></error-notice>
        <card-grid>
            <progress-dots v-if="!accounts && !error"></progress-dots>
            <div v-else class="card" v-for="(account, name) in accounts" :key="name">
                <header>
                    <h2 class="status" :class="{enabled: account.active}">{{name}}</h2>
                    <div class="button-row">
                        <button @click="deleteAccount(account)">Delete</button>
                        <button @click="toggleAccount(account)">{{account?.active ? 'Disable' : 'Enable'}}</button>
                    </div>
                </header>
                <div v-if="keys[name]"><strong>API Key</strong>: <clip-link :value="keys[name]"></clip-link></div>
                <div class="list-user" v-for="scope in account.scope" :key="scope" @contextmenu.stop.prevent="showMenu(scope, name)">
                    <a class="list-user-link" :class="{active: isMenuVisible(scope, name)}" @click.stop="showMenu(scope, name)">{{scope}}</a>
                    <div class="popup-menu" v-if="isMenuVisible(scope, name)">
                        <ul>
                            <a @click="removeScope(account, scope)"><li>Remove account scope</li></a>
                        </ul>
                    </div>
                </div>
                <div class="add-row">
                    <input-button type="text" placeholder="New scope" @click="addScope(account)" v-model="account.input">Add</input-button>
                </div>
            </div>

        </card-grid>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import CardGrid from './CardGrid.vue';

    @Component
    export default class AccountsPage extends CardGrid {

        error = null;
        accounts = null;
        input = '';
        menu = null;
        keys = {};

        mounted() {
            this.refreshAccounts();
        }

        async refreshAccounts() {

            try {
                this.accounts = await this.dal.fetchApiAccounts();

            }catch(error) {
                this.accounts = null;
                this.error = error;
            }
        }

        async newAccount(name) {
            try {
                let account = await this.dal.addApiAccount(name);
                
                this.input = '';
                this.keys[name] = account.key;
                this.refreshAccounts();

            }catch(error) {
                this.error = error;
            }
        }

        async toggleAccount(account) {
            try {
                await this.dal.updateApiAccountStatus(account.name, !account.active);
                this.refreshAccounts();

            }catch(error) {
                this.error = error;
            }
        }

        async deleteAccount(account) {
            try {
                await this.dal.deleteApiAccount(account.name);
                this.refreshAccounts();

            }catch(error) {
                this.error = error;
            }
        }

        async addScope(account) {
            try {
                await this.dal.addApiAccountScope(account.name, account.input);

                this.refreshAccounts();

            }catch(error) {
                this.error = error;
            }
        }

        async removeScope(account, scope) {
            try {
                await this.dal.removeApiAccountScope(account.name, scope);

                this.refreshAccounts();

            }catch(error) {
                this.error = error;
            }
        }

        showMenu(scope, name) {

            let key = name + ':' + scope;
            if(this.menu === key) {
                this.dismissMenu();
                return;
            }
            
            this.menu = key;
            console.log(`Show menu: ${this.menu}`);
            document.addEventListener('click', this.dismissMenu);
            document.addEventListener('contextmenu', this.dismissMenu);
            return true;
        }

        isMenuVisible(scope, name) {

            let key = name + ':' + scope;
            return this.menu === key;
        }

        dismissMenu(e) {
            this.menu = null;

            console.log(`Dismiss menu`);
            document.removeEventListener('click', this.dismissMenu);
            document.removeEventListener('contextmenu', this.dismissMenu);

            e?.stopPropagation();
        }
    }

    Vue.component('accounts-page', AccountsPage);

</script>

