var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "promotion-banner" },
    [
      _vm.promo?.image
        ? _c("img", {
            attrs: { src: _vm.promo?.image },
            on: {
              click: function ($event) {
                return _vm.click(_vm.active)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.banners.length > 1
        ? _c("paginator", {
            attrs: { count: _vm.banners.length, cycle: 5000 },
            model: {
              value: _vm.active,
              callback: function ($$v) {
                _vm.active = $$v
              },
              expression: "active",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }