<style lang="scss" scoped>
    @import './dashboard.scss';
    @import 'styles/colors.scss';

    header {
        margin: 0;
        padding: 0;

        .input-button {
            margin: 0 5px;
            max-width: 350px;
        }
    }

    .card-grid {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr;
       // grid-template-rows: 500px 200px 300px;
        grid-template-areas:
            'card1'
            'card2'
            'card3'
            'card4';
        grid-gap: 10px;

        .card {
            padding: 20px;
            border: 1px solid $general-blue-dark;
            border-radius: 4px;
            color: $general-blue-dark;
            background-color: $general-blue-glow;
            overflow-wrap: anywhere;
        }

        .card:first-child {
            grid-area: card1;
        }
        .card:nth-child(2) {
            grid-area: card2;
        }
        .card:nth-child(3) {
            grid-area: card3;
        }
        .card:nth-child(4) {
            grid-area: card4;
        }

        @media only screen and (min-width: 750px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
           // grid-template-rows: 250px 250px;
            grid-template-areas:
                'card1 card2'
                'card1 card3'
                'card4 card4';
            grid-gap: 10px;
        }

        a {
            @include hover-underline($general-blue-dark);
        }
    }

    .list-user {
        margin: 1px 0;
    }

    .popup-menu {
        color:white;
        position: absolute;
        z-index: 5;

        background-color: $menu-colour;
        border-radius: 0 $menu-radius $menu-radius $menu-radius;

        padding: 0 15px 10px;

        ul {
            padding: 5px;
        }
        
        li {
            padding: 10px 2px;

            @include hover-underline(white);
        }

    }



</style>

<template>
    <div class="card-grid">
        <slot></slot>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import Environment from 'common/Environment';

    const CardGridProps = Vue.extend({
        props: {
            args: {
                args: String,
                required: false
            }
        }
    });

    @Component
    export default class CardGrid extends CardGridProps {

        get dal() {
            return window.dal;
        }


        get config() {
            return Environment.config;
        }

        get appUrl() {
            return Environment.config.webAppURL;
        }

        get storeUrl() {
            return Environment.config.storeURL;
        }

        get functionUrl() {
            return Environment.config.functionURL;
        }
    }

    Vue.component('card-grid', CardGrid);

</script>

