<style lang="scss" scoped>
    @import './dashboard.scss';
    @import 'styles/colors.scss';

    header {
        margin: 0;
        padding: 0;

        .input-button {
            margin: 0 5px;
            max-width: 350px;
        }
    }

    button {
        a {
            font-weight: bold;
        }
    }

    .status::after {
        content: '';
        display: inline-block;
        box-sizing: border-box;
        background-color: $validation-bad;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        border: solid 0.5px $mono-dark;
        margin: 0 2px;
    }

    .status {
        &.online::after {
            background-color: $validation-good;
        }       
    }

</style>

<template>
    <div>
        <error-notice v-if="error" :error="error"></error-notice>
        <header>
            <h1>User Profile</h1>
            <input-button v-model="lookup" :maxlength="28" placeholder="Enter uid to search"><a :href="'#/user/' + lookup">Search</a></input-button>
        </header>
        <card-grid v-if="profile">
            <div class="card">
                <h2>{{profile?.username}}</h2>
                <div><strong>Username</strong>: {{profile?.username}}</div>
                <div><strong>UID</strong>: <clip-link :value="profile?.uid"></clip-link></div>
                <div><strong>Email</strong>: <clip-link :value="profile?.email"></clip-link></div>
            </div>
            <div class="card">
                <h2>Status</h2>
                <div class="status" :class="{online: status('online_ios')}"><strong>iOS</strong>: </div>
                <div class="status" :class="{online: status('online_android')}"><strong>Android</strong>: </div>
                <div class="status" :class="{online: status('online_web')}"><strong>Web</strong>: </div>
            </div>
            <div class="card">
                <h2>Last Login</h2>
                <div v-if="profile?.lastlogin_ios"><strong>iOS</strong>: {{profile?.lastlogin_ios}}</div>
                <div v-if="profile?.lastlogin_android_ab"><strong>Android AB</strong>: {{profile?.lastlogin_android_ab}}</div>
                <div v-if="profile?.lastlogin_android_bc"><strong>Android BC</strong>: {{profile?.lastlogin_android_bc}}</div>
                <div v-if="profile?.lastlogin_android_mb"><strong>Android MB</strong>: {{profile?.lastlogin_android_mb}}</div>
                <div v-if="profile?.lastlogin_android_nb"><strong>Android NB</strong>: {{profile?.lastlogin_android_nb}}</div>
                <div v-if="profile?.lastlogin_android_ns"><strong>Android NS</strong>: {{profile?.lastlogin_android_ns}}</div>
                <div v-if="profile?.lastlogin_android_on"><strong>Android ON</strong>: {{profile?.lastlogin_android_on}}</div>
                <div v-if="profile?.lastlogin_android_qb"><strong>Android QB</strong>: {{profile?.lastlogin_android_qb}}</div>
                <div v-if="profile?.lastlogin_android_sk"><strong>Android SK</strong>: {{profile?.lastlogin_android_sk}}</div>
                <div v-if="profile?.lastlogin_android_yt"><strong>Android YT</strong>: {{profile?.lastlogin_android_yt}}</div>
            </div>
            <div class="card">
                <h2>Purchaser ID</h2>
                <div v-if="profile?.purchaser_id_ios"><strong>iOS</strong>: {{profile?.purchaser_id_ios}}</div>
                <div v-if="profile?.purchaser_id_android_ab"><strong>Android AB</strong>: {{profile?.purchaser_id_android_ab}}</div>
                <div v-if="profile?.purchaser_id_android_bc"><strong>Android BC</strong>: {{profile?.purchaser_id_android_bc}}</div>
                <div v-if="profile?.purchaser_id_android_mb"><strong>Android MB</strong>: {{profile?.purchaser_id_android_mb}}</div>
                <div v-if="profile?.purchaser_id_android_nb"><strong>Android NB</strong>: {{profile?.purchaser_id_android_nb}}</div>
                <div v-if="profile?.purchaser_id_android_ns"><strong>Android NS</strong>: {{profile?.purchaser_id_android_ns}}</div>
                <div v-if="profile?.purchaser_id_android_on"><strong>Android ON</strong>: {{profile?.purchaser_id_android_on}}</div>
                <div v-if="profile?.purchaser_id_android_qb"><strong>Android QB</strong>: {{profile?.purchaser_id_android_qb}}</div>
                <div v-if="profile?.purchaser_id_android_sk"><strong>Android SK</strong>: {{profile?.purchaser_id_android_sk}}</div>
                <div v-if="profile?.purchaser_id_android_yt"><strong>Android YT</strong>: {{profile?.purchaser_id_android_yt}}</div>
            </div>
            <div class="card">
                <h2>Profile</h2>
                <div>/users/{{uid}}</div>
                <json-viewer v-if="profile" :value="profile" :expand-depth="0"></json-viewer>
            </div>
            <div class="card">
                <h2>Summary</h2>
                <div>User account summary</div>
                <json-viewer v-if="summary" :value="summary" :expand-depth="0"></json-viewer>
            </div>
            <div class="card">
                <h2>Purchases</h2>
                <div>User purchases</div>
                <json-viewer v-if="purchases" :value="purchases" :expand-depth="0"></json-viewer>
            </div>
        </card-grid>
        <h3 v-else-if="uid">No user profile found for uid: <em>{{uid}}</em></h3>
        <h3 v-else>Search by UID</h3>
    </div>
</template>

<script>
    import { Component, Watch, Vue } from 'vue-property-decorator';
    import JsonViewer from 'vue-json-viewer';
    import CardGrid from './CardGrid.vue';

    @Component({
        components: {
            JsonViewer
        }
    })
    export default class UserPage extends CardGrid {

        lookup = null;
        profile = null;
        summary = null;
        purchases = null;
        error = null;

        @Watch('args')
        onArgsChanged() {
            this.init();
        }

        mounted() {
            this.init();
        }

        async init() {
            this.lookup = this.uid;

            if(this.uid) {
                try {
                    this.profile = await this.dal.loadUserProfile(this.uid);

                }catch(error) {
                    this.profile = null;
                    this.error = error;
                }

                try {
                    this.summary = await this.dal.getUserSummary(this.uid);

                }catch(error) {
                    this.summary = null;
                    this.error = error;
                }

                try {
                    this.purchases = await this.dal.getUserPurchases(this.uid);

                }catch(error) {
                    this.purchases = null;
                    this.error = error;
                }
            }
        }

        get uid() {
            return this.args ? this.args.substring(0, 28) : null; // Max uid length
        }

        status(platformKey) {
            return this.profile[platformKey];// ? "online" : "offline";
        }
    }

    Vue.component('user-page', UserPage);

</script>

