var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v("Product Catalog")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("h2", [_vm._v("Firebase")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tabs",
          _vm._l(_vm.firebaseCatalog, function (region, key) {
            return _c(
              "tab",
              { key: key, attrs: { title: region?.short } },
              [
                _c("catalog-explorer", {
                  attrs: {
                    id: key,
                    catalog: region,
                    provider: _vm.firebaseProvider,
                  },
                }),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("h2", [_vm._v("Hosted")]),
        _vm._v(" "),
        _c("p", [
          _c("span", [
            _vm._v(
              "Product catalog as hosted in JSON (.json) metadata files on the WebApp (" +
                _vm._s(this.webServer) +
                ") and Tile resource (" +
                _vm._s(this.tileServer) +
                ") servers. Used by iOS, Android and WebApp clients."
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.empty(_vm.hostedCatalog)
          ? _c("button", { on: { click: _vm.loadHostedRegions } }, [
              _vm._v("Build catalog"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.empty(_vm.hostedCatalog) ? _c("p", [_vm._m(1)]) : _vm._e(),
        _vm._v(" "),
        _c(
          "tabs",
          _vm._l(_vm.sortedCatalog, function (region, key) {
            return _c(
              "tab",
              { key: key, attrs: { title: region?.short } },
              [
                _vm.merging
                  ? _c("progress-dots", [_vm._v("Merging metadata")])
                  : _c("catalog-explorer", {
                      attrs: {
                        id: region?.id,
                        catalog: region,
                        provider: _vm.hostedProvider,
                      },
                      on: { merge: _vm.mergeCatalogMetadata },
                    }),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [_vm._v("Product catalog in Firebase. Used by the Store.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("To begin, click "),
      _c("b", [_vm._v("Build catalog")]),
      _vm._v(" to iteratively query the top-level "),
      _c("i", [_vm._v("provinces.json")]),
      _vm._v(" and subsequent "),
      _c("i", [_vm._v("province.json")]),
      _vm._v(
        " metadata for each region and their associated subscription and layers "
      ),
      _c("i", [_vm._v("metadata.json")]),
      _vm._v(" and "),
      _c("i", [_vm._v("counties.json")]),
      _vm._v(
        ". Please be patient - loading the full catalog will request hundereds of files from our servers."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }