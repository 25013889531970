var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "header",
        [
          _c("h1", [_vm._v("Access Control")]),
          _vm._v(" "),
          _c(
            "input-button",
            {
              attrs: { maxlength: 64, placeholder: "New scope key" },
              on: {
                click: function ($event) {
                  return _vm.newScope()
                },
              },
              model: {
                value: _vm.input,
                callback: function ($$v) {
                  _vm.input = $$v
                },
                expression: "input",
              },
            },
            [_vm._v("Create")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.error
        ? _c("error-notice", { attrs: { error: _vm.error } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "card-grid",
        [
          !_vm.scopes.length
            ? _c("progress-dots")
            : _vm._l(_vm.scopes, function (scope) {
                return _c(
                  "div",
                  { key: scope.key, staticClass: "card" },
                  [
                    _c("h2", [_vm._v(_vm._s(scope.key))]),
                    _vm._v(" "),
                    _vm._l(scope.users, function (user) {
                      return _c(
                        "div",
                        {
                          key: user.uid,
                          staticClass: "list-user",
                          on: {
                            contextmenu: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.showMenu(scope, user)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "list-user-link",
                              class: { active: _vm.isMenuVisible(scope, user) },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showMenu(scope, user)
                                },
                              },
                            },
                            [_vm._v(_vm._s(user.username))]
                          ),
                          _vm._v(" "),
                          _vm.isMenuVisible(scope, user)
                            ? _c("div", { staticClass: "popup-menu" }, [
                                _c("ul", [
                                  _c(
                                    "a",
                                    { attrs: { href: "#/user/" + user.uid } },
                                    [_c("li", [_vm._v("View user profile")])]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeScope(scope, user)
                                        },
                                      },
                                    },
                                    [_c("li", [_vm._v("Remove user scope")])]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-row" },
                      [
                        _c(
                          "input-button",
                          {
                            attrs: {
                              type: "text",
                              placeholder: "User UID or name",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addScope(scope)
                              },
                            },
                            model: {
                              value: scope.input,
                              callback: function ($$v) {
                                _vm.$set(scope, "input", $$v)
                              },
                              expression: "scope.input",
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }