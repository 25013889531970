<style lang="scss" scoped>


</style>

<template>
    <div>
        <h1>Manage Store</h1>

        <card-grid>
            <div class="card">
                <img src="https://i.imgur.com/fuv6iyl.gif">
            </div>

        </card-grid>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import CardGrid from './CardGrid.vue';

    @Component
    export default class StorePage extends CardGrid {

    }

    Vue.component('store-page', StorePage);

</script>

