import { getDatabase, ref as databaseRef, get, set, update, query, onValue } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, uploadBytesResumable, deleteObject, getDownloadURL } from "firebase/storage";

export default class FirebaseRepository  {

    constructor() {
        this.db = getDatabase();
        this.storage = getStorage();
    }


    async get(path) {
        let snapshot = await get(databaseRef(this.db, path));
        return snapshot.exists() ? snapshot.val() : null;
    }

    async update(path, data) {
        return await update(databaseRef(this.db, path), data);
    }

    async set(path, data) {
        return await set(databaseRef(this.db, path), data);
    }

    async watch(path, notify, error) {
        return onValue(query(databaseRef(this.db, path)), notify, error);
    }

    encodeKey(key) {
        return key.replace(
            /[\/\.\$\[\]#!]/g,
            (match) => `!${match.charCodeAt(0).toString(16).toUpperCase()}`
        );
    }

    decodeKey(key) {
        return key.replace(/!([0-9a-f]{2})/gi, (match, code) => {
            return String.fromCharCode(parseInt(code, 16));
        })
    }

    async uploadObject(path, file, metadata) {
        return await uploadBytes(storageRef(this.storage, path), file, metadata);
    }

    async deleteObject(path) {
        return await deleteObject(storageRef(this.storage, path));
    }

    async objectUrl(path) {
        return await getDownloadURL(storageRef(this.storage, path));
    }
}
