var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-notice" },
    [
      _c("p", [_vm._v("To use iHunter on your mobile device:")]),
      _vm._v(" "),
      _c("p", [
        _c("a", { attrs: { href: _vm.playStoreUrl } }, [
          _c("img", {
            attrs: {
              src: require("../../common/assets/play_store-badge.png"),
              alt: "Get it on Google Play",
              height: "40px",
            },
          }),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: _vm.appStoreUrl } }, [
          _c("img", {
            attrs: {
              src: require("../../common/assets/app_store-badge.png"),
              alt: "iHunter on the App Store",
              height: "40px",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.web
        ? _c("span", [
            _vm._v("Are you looking for the "),
            _c("a", { attrs: { href: _vm.web } }, [_vm._v("iHunter Web App")]),
            _vm._v("?"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }