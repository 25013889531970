export default class CatalogProvider {

    constructor(product) {
        this.product = product;
        this.regions = {};

        this.catalog = {};
        this.writable = false;
    }

    get readonly() {
        return !this.writable;
    }

    processRegions(regions, notify) {
        let old = {};
        Object.assign(old, this.regions);

        for(let id of regions) {
            if(!this.regions[id]) {
                //console.log("Watching region: " + id);
                this.regions[id] = true;
                this.watchRegion(id, notify);
                
            }else { // already exists
                if(old[id]) {
                    delete old[id];
                }
            }
        }

        for(let id in old) {
            console.log("Unwatching region: " + id);
            delete this.regions[id];
            this.unwatchRegion(id);
        }
    }

    unwatchCatalog() {
        // Gets overwritten during watchCatalog
    }

    unwatchRegion(id) {

        // Gets overwritten during unwatchRegion
    }
} 
