import { isBrowser, isNode } from "browser-or-node";
import ProjectConfig from '../../project-config.json';

const USE_DEV_WHEN_LOCAL = false;

export default class Environment {

    static get config() {
        return ProjectConfig[this.projectId];
    }

    static get projectId() {
        let firebase = this.firebaseConfig();
        if(firebase) { // Check if we are running server-side
            return firebase.projectId;
        }
        if(this.isProduction()) {
            return 'ihunter-d5eab';
        }
        if(this.isDevelopment()) {
            return 'ihunterdev';
        }
        if(this.isLocal() && USE_DEV_WHEN_LOCAL) {
            return 'hybrid';
        }
        if(this.isLocal()) {
            return 'localhost';
        }
        return 'localhost';
    }

    static isBrowser() {
        return isBrowser;
    }
    
    static isNode() {
        return isNode;
    }

    static firebaseConfig() {
        return process.env.FIREBASE_CONFIG && JSON.parse(process.env.FIREBASE_CONFIG);
    }

    static isLocal() {
        return window.location.hostname.includes('localhost');
    }

    static isDevelopment() {
        return window.location.hostname.includes('dev');
    }

    static isProduction() {
        return !this.isDevelopment() && !this.isLocal();
    }
}