var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Promotions")]),
      _vm._v(" "),
      _c("card-grid", [
        _c("div", { staticClass: "card" }, [
          _c("h2", [_vm._v("Edit")]),
          _vm._v(" "),
          _vm.selected === null
            ? _c("p", [
                _c("span", [
                  _vm._v("Select or create new promotion on the right"),
                ]),
              ])
            : _vm.promo
            ? _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "active" } }, [
                      _vm._v("Active"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.promo.active,
                            expression: "promo.active",
                          },
                        ],
                        attrs: { type: "checkbox", id: "active" },
                        domProps: {
                          checked: Array.isArray(_vm.promo.active)
                            ? _vm._i(_vm.promo.active, null) > -1
                            : _vm.promo.active,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.promo.active,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.promo,
                                      "active",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.promo,
                                      "active",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.promo, "active", $$c)
                              }
                            },
                            function ($event) {
                              _vm.changes = true
                            },
                          ],
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "name" } }, [_vm._v("Name")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.promo.name,
                            expression: "promo.name",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "name",
                          name: "name",
                          placeholder: "",
                        },
                        domProps: { value: _vm.promo.name },
                        on: {
                          change: function ($event) {
                            _vm.changes = true
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.promo, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "desc" } }, [
                      _vm._v("Description"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.promo.description,
                            expression: "promo.description",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "desc",
                          name: "desc",
                          placeholder: "",
                        },
                        domProps: { value: _vm.promo.description },
                        on: {
                          change: function ($event) {
                            _vm.changes = true
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.promo,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "tagline" } }, [
                      _vm._v("Cart tagline"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.promo.tagline,
                            expression: "promo.tagline",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "tagline",
                          name: "tagline",
                          placeholder: "Save 20% at checkout with promo code:",
                        },
                        domProps: { value: _vm.promo.tagline },
                        on: {
                          change: function ($event) {
                            _vm.changes = true
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.promo, "tagline", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "code" } }, [
                      _vm._v("Promo code"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.promo.code,
                            expression: "promo.code",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "code",
                          name: "code",
                          placeholder: "PROMOCODE",
                        },
                        domProps: { value: _vm.promo.code },
                        on: {
                          change: function ($event) {
                            _vm.changes = true
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.promo, "code", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "start" } }, [
                      _vm._v("Start date"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("date-pick", {
                          attrs: { id: "start" },
                          on: {
                            change: function ($event) {
                              _vm.changes = true
                            },
                          },
                          model: {
                            value: _vm.promo.start,
                            callback: function ($$v) {
                              _vm.$set(_vm.promo, "start", $$v)
                            },
                            expression: "promo.start",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "end" } }, [
                      _vm._v("End date"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("date-pick", {
                          attrs: { id: "end" },
                          on: {
                            change: function ($event) {
                              _vm.changes = true
                            },
                          },
                          model: {
                            value: _vm.promo.end,
                            callback: function ($$v) {
                              _vm.$set(_vm.promo, "end", $$v)
                            },
                            expression: "promo.end",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "image" } }, [_vm._v("Image")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      !_vm.promo.image
                        ? _c("input", {
                            attrs: {
                              id: "image",
                              type: "file",
                              accept: "image/*",
                            },
                            on: { change: _vm.upload },
                          })
                        : _c("div", [
                            _c(
                              "button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.promo.image = null
                                    _vm.changes = true
                                  },
                                },
                              },
                              [_vm._v("Clear image")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.promo.image,
                                  target: "_blank",
                                },
                              },
                              [_c("img", { attrs: { src: _vm.promo.image } })]
                            ),
                          ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Manage")]),
            _vm._v(" "),
            _vm.changes
              ? _c(
                  "button",
                  {
                    attrs: { type: "submit", disabled: _vm.processing },
                    on: { click: _vm.save },
                  },
                  [_c("span", [_vm._v("Save changes")])]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _c("span", [
                _vm._v("Select from the list to manage store promotions"),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.promotions, function (promotion, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "list-user list-item",
                  class: { selected: _vm.selected === index },
                  on: {
                    contextmenu: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.showMenu(promotion)
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "list-user-link",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.showMenu(promotion)
                        },
                      },
                    },
                    [_vm._v("‣" + _vm._s(promotion.name))]
                  ),
                  _vm._v(" "),
                  _vm.isMenuVisible(promotion)
                    ? _c("div", { staticClass: "popup-menu" }, [
                        _c("ul", [
                          _vm.selected != index
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.selected = index
                                    },
                                  },
                                },
                                [_c("li", [_vm._v("Edit")])]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index > 0
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.swapElements(index, index - 1)
                                    },
                                  },
                                },
                                [_c("li", [_vm._v("Move up")])]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          index < _vm.promotions.length - 1
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.swapElements(index, index + 1)
                                    },
                                  },
                                },
                                [_c("li", [_vm._v("Move down")])]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.remove(index)
                                },
                              },
                            },
                            [_c("li", [_vm._v("Remove")])]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-row" },
              [
                _c(
                  "input-button",
                  {
                    attrs: { type: "text", placeholder: "Unique ID" },
                    on: {
                      click: function ($event) {
                        return _vm.add(_vm.input)
                      },
                    },
                    model: {
                      value: _vm.input,
                      callback: function ($$v) {
                        _vm.input = $$v
                      },
                      expression: "input",
                    },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Firebase")]),
            _vm._v(" "),
            _c("b", [_vm._v("/store/promotions/")]),
            _vm._v(" "),
            _c("json-viewer", {
              attrs: { value: _vm.database, "expand-depth": 2 },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Banner")]),
            _vm._v(" "),
            _c("promotion-banner", {
              attrs: { promotions: _vm.promotions },
              on: { click: _vm.clickPromo },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }