// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/chevron.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".expandable[data-v-c1f01672]{padding-left:8px;padding-right:8px}.expandable .header[data-v-c1f01672]{display:inline-block}.expandable .chevron[data-v-c1f01672]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;background-position:center center;cursor:pointer;width:10px;height:10px;margin-right:2px;display:inline-block;transition:transform .1s}.expandable .chevron.open[data-v-c1f01672]{transform:rotate(90deg)}.expandable .content[data-v-c1f01672]{padding-left:8px}", "",{"version":3,"sources":["webpack://./../common/components/Expandable.vue"],"names":[],"mappings":"AAEA,6BAEI,gBAAA,CACA,iBAAA,CAEA,qCACI,oBAAA,CAGJ,sCACI,wDAAA,CACA,2BAAA,CACA,uBAAA,CACA,iCAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,oBAAA,CAEA,wBAAA,CAEA,2CACI,uBAAA,CAIR,sCACI,gBAAA","sourcesContent":["\n@import 'styles/colors.scss';\n.expandable {\n\n    padding-left: 8px;\n    padding-right: 8px;\n\n    .header {\n        display: inline-block;\n    }\n\n    .chevron {\n        background-image: url(\"../assets/chevron.svg\");\n        background-repeat: no-repeat;\n        background-size: contain;\n        background-position: center center;\n        cursor: pointer;\n        width: 10px;\n        height: 10px;\n        margin-right: 2px;\n        display: inline-block;\n\n        transition: transform 0.1s;\n\n        &.open {\n            transform: rotate(90deg);\n        }\n    }\n\n    .content {\n        padding-left: 8px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
