import CatalogPropertyMap from './CatalogPropertyMap';
import splitProduct from '../libs/splitProduct';
import allMap from '../libs/allMap';
Promise.allMap = allMap;

export default class CatalogBuilder {

    constructor(provider) {
        this.provider = provider;
        this.includeMetadata = true;

        this.countyLayerBoundarydata = {}; // boundary data cache
    }

    async getRegions() { // Array of region id's
        return this.provider.getRegions();
    }

    copyProperties(target, source, map) {
        for(let key in map) {
            const property = map[key];
            if(source[property] && property) {
                target[key] = source[property];
            }
        }
        return target;
    }

    addMetadata(target, source) {
        if(this.includeMetadata && target && source) {
            target.metadata = source;
        }
    }
 
    async getRegion(region) {

        let metadata = await this.provider.getRegionMetadata(region);

        let summary = {
            id: region,
            product: this.provider.product,
            resources: {}
        };

        this.addMetadata(summary, metadata);
        this.copyProperties(summary, metadata, CatalogPropertyMap.RegionPropertyMap());

        // Resources and layers
        let id = summary.resource_id || region; // userresource_id (aka SERVER_FOLDER) as region id when enumerating resources
        
        if(metadata && metadata.required_resources) {
            let promises = {};
            for(let resource of metadata.required_resources) {
                promises[resource] = this.getResource(id, resource);
                //summary.resources[resource] = await this.getResource(id, resource);
            }
            summary.resources = await Promise.allMap(promises);
        }

        // Subscription
        if(metadata && metadata.subscriptions) {
            //let subscription = await this.getSubscription(region);
            let promises = {};
            for(let subscription of metadata.subscriptions) {
                promises[subscription] = this.getSubscription(id, subscription);
                //summary.resources[resource] = await this.getResource(id, resource);
            }
            summary.subscriptions = await Promise.allMap(promises);//await this.getSubscriptions(region);
        }

        return summary;
    }

    async getResource(region, resource) {

        let metadata = await this.provider.getResourceMetadata(region, resource);
        let summary = {
            layers: {}
        };

        this.addMetadata(summary, metadata);
        this.copyProperties(summary, metadata, CatalogPropertyMap.ResourcePropertyMap());

        let promises = {};
        for(let layer of metadata.layers) {
            
            promises[layer] = this.getResourceLayer(region, resource, layer);
        }
        summary.layers = await Promise.allMap(promises);

        return summary
    }
    
    async getResourceLayer(region, resource, layer) {

        let metadata = await this.provider.getResourceLayerMetadata(region, resource, layer);
        let summary = {};

        this.addMetadata(summary, metadata);
        this.copyProperties(summary, metadata, CatalogPropertyMap.ResourceLayerPropertyMap());

        // County in-app purchase (special-case)
        if(resource === 'purchasable_layers' && layer === 'county') {
            summary.maps = await this.getCountyMaps(region);
        }

        return summary;
    }

    async getSubscription(region, subscription) {
        let metadata = await this.provider.getSubscriptionMetadata(region, subscription);
        let summary = {};

        this.addMetadata(summary, metadata);
        this.copyProperties(summary, metadata, CatalogPropertyMap.SubscriptionPropertyMap());

        if(metadata.layers) {
            summary.layers = {};
            
            let promises = {};
            for(let layer of metadata.layers) {
                promises[layer] = this.getSubscriptionLayer(region, subscription, layer);
            }
            summary.layers = await Promise.allMap(promises);
        }
        
        // let result = {};
        // result[summary.sku] = summary;
        return summary;
    }

    async getSubscriptionLayer(region, subscription, layer) {
        let summary = {};
        try {
            let metadata = await this.provider.getSubscriptionLayerMetadata(region, subscription, layer);
            if(!metadata) {
                return summary;
            }

            this.addMetadata(summary, metadata);
            this.copyProperties(summary, metadata, CatalogPropertyMap.SubscriptionLayerPropertyMap());
            
        }catch(error) {
            console.error(error);
        }

        return summary;
    }

    async getCountyMapMetadata(region, map) {
        if(!this.countyLayerBoundarydata[region]) { // Lame cache
            this.countyLayerBoundarydata[region] = await this.provider.getCountyLayerBoundarydata(region);
        }
        let boundarydata = this.countyLayerBoundarydata[region];
        if(boundarydata){
            for(let area in boundarydata) {
                let boundary = boundarydata[area];
                let product = boundary[CatalogPropertyMap.ResourceLayerBoundarydataPropertyMap().product];
                if(map === product) { // Only copy boundarydata for counties we have for sale
                    let metadata = {};
                    this.copyProperties(metadata, boundary, CatalogPropertyMap.ProductBoundarydataPropertyMap());
                    return metadata;
                }
            }
        }
        console.error("getCountyMapMetadata failed to metch metadata for " + map);
        return null;
    }

    async getCountyMaps(region) {
       // let maps = {};

        let metadata = await this.provider.getCountyLayerMetadata(region);
        let promises = {};
        for(let county of metadata.counties) {
            // Parse product id from sku
            let product = splitProduct(county);
            if(!promises[product.map]) {
                promises[product.map] = {
                    products: {}
                };
            }

            promises[product.map].products[county] = this.getCountyProduct(region, county);

        }
        let maps = await Promise.allMap(promises);

        // Apply freeupdate property
        for(let county in metadata.freeupdates) {
            let updated = metadata.freeupdates[county];
            let product = splitProduct(updated);
            maps[product.map].products[updated].freeupdate = county;
        }

        let boundarydata = await this.provider.getCountyLayerBoundarydata(region);
        if(boundarydata){
            for(let area in boundarydata) {
                let boundary = boundarydata[area];
                let product = boundary[CatalogPropertyMap.ResourceLayerBoundarydataPropertyMap().product];
                if(maps[product]) { // Only copy boundarydata for counties we have for sale
                    this.copyProperties(maps[product], boundary, CatalogPropertyMap.ProductBoundarydataPropertyMap());
                }
            }
        }

        return maps;
    }

    async getCountyProduct(region, county) {
        let product = splitProduct(county);
        let summary = {
            map: product.map,
            version: product.version
        };

        // Not mapping anything, so lets save the network traffic
        if(this.includeMetadata) {
            this.addMetadata(summary, await this.provider.getCountyProductMetadata(region, county));
        }
        return summary;
    }

    // getProductId(sku) {
    //     let product = sku.slice(0, -5);     // Remove date
    //     return product.replace(/\W+$/, ""); // Trim non-alphanumeric
    // }
    
    // splitMapProduct(sku) {
    //     let split = sku.lastIndexOf('_');
    //     let version = sku.slice(split + 1);
    //     let product = sku.slice(0, split);     // Remove date
    //     return {
    //         map: product.replace(/(^[^A-Za-z0-9]*)|([^A-Za-z0-9]*$)/g, ""), // Trim non-alphanumeric
    //         version: version
    //     };
    // }
}