// Load components
import './CardGrid.vue';
import './UserPage.vue';
import './AccessPage.vue';
import './StorePage.vue';
import './ProductsPage.vue';
import './CatalogPage.vue';
import './AffiliatesPage.vue';
import './PromotionsPage.vue';
import './StatusPage.vue';
import './AccountsPage.vue';
import './ServiceProbe.vue';
import './CertProbe.vue';
import './ErrorNotice.vue';
import './InputButton.vue';


// Load common components
import 'components/AppNotice.vue';
import 'components/ProgressDots.vue';
import 'components/ClipLink.vue';
import 'components/Tab.vue';
import 'components/Tabs.vue';
import 'components/Expandable.vue';
import 'components/JsonDiff.vue';
import 'components/CatalogExplorer.vue'
import 'components/PromotionBanner.vue';

// Load styles
import 'styles/base.scss';
import './dashboard.scss';

import 'firebaseui/dist/firebaseui.css';

// Load app view
import App from 'common/App';
import Dashboard from './Dashboard.vue';
window.app = new App(Dashboard); // App entry-point

// Load data access layer
import AdminDataAccessLayer from './AdminDataAccessLayer';
window.dal = new AdminDataAccessLayer(window.app);