var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tooltip" }, [
    _c("span", { staticClass: "tooltiptext" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.copied ? "Copied" : "Copy to clipboard") +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        on: {
          click: function ($event) {
            return _vm.copy()
          },
          mouseout: function ($event) {
            return _vm.reset()
          },
        },
      },
      [
        _vm._t("default", function () {
          return [_vm._v(_vm._s(_vm.value))]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }