<style lang="scss" scoped>
    @import 'styles/colors.scss';

    .cert-probe {

        .expiry::after {
            content: '';
            display: inline-block;
            box-sizing: border-box;
            background-color: $validation-weak;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            border: solid 0.5px $mono-dark;
            margin: 0 2px;
        }

        .expiry {
            &.far::after {
                background-color: $validation-good;
            }

            &.near::after {
                background-color: $validation-bad;
            }            
        }
    }

</style>

<template>
    <div class="cert-probe">
        <div v-if="certificate && !expired" class="expiry" :class="{far: days >= 60, near: days < 30}"><strong>Certificate</strong>: expires in <strong>{{days}}</strong> days</div>
        <div v-else-if="expired" class="expiry error near"><strong>Error</strong>: {{certificate?.error}}</div>
        <div v-else-if="error" class="expiry warning"><strong>Warning</strong>: {{error?.details}}</div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    const CertProbeProps = Vue.extend({
        props: {
            url: {
                type: String,
                required: true
            }
        }
    });

    @Component
    export default class CertProbe extends CertProbeProps {

        error = null;
        certificate = null;

        async mounted() {

            if(!this.url) {
                return;
            }

            try {
                let response = await this.dal.fetchCertExpiry(this.url);
                if(!response) {
                    throw new Error("Null response");
                }
                this.certificate = response;
                
            }catch(error) {
                console.warn(error);
                this.error = error;
            }
        }
        
        get expired() {
            return this.certificate && (this.certificate.error || this.certificate.days === 0);
        }

        get days() {
            return this.certificate && this.certificate.days ? this.certificate.days : 0;
        }
        
        get dal() {
            return window.dal;
        }
    }

    Vue.component('cert-probe', CertProbe);

</script>

