import { query, ref, onValue, orderByChild, equalTo } from "firebase/database";

import FirebaseRepository from './FirebaseRepository';
import CatalogFirebaseResources from './CatalogFirebaseResources';
import CatalogProvider from './CatalogProvider';

export default class CatalogFirebaseProvider extends CatalogProvider {
    
    constructor(product) {
        super(product);
        
        this.firebase = new FirebaseRepository();

        this.writable = true;
    }

    watchCatalog(notify) {
        console.log(`Watching ${this.product} product regions`);
         
        let path = ref(this.firebase.db, CatalogFirebaseResources.AllRegions());
        this.unwatchCatalog = onValue(query(path, orderByChild('product'), equalTo(this.product)), (snapshot) => {
            let catalog = snapshot.val();
            if(catalog) {
                let regions = Object.keys(catalog);
                this.processRegions(regions, notify);
            }

        }, (error) => {
            console.error(`Watching ${this.product} product regions`, error);
        });
    }

    watchRegion(id, notify) {
        console.log(`Watching ${this.product} ${id} product catalog`);

        let path = ref(this.firebase.db, CatalogFirebaseResources.Region(id));
        this.unwatchRegion[id] = onValue(query(path), (snapshot) => {
            this.catalog[id] = snapshot.val();
            notify(id, this.catalog[id]);

        }, (error) => {
            console.error(`Watching ${id} product catalog`, error);
        });
    }

    unwatchRegion(id) {

        if(this.unwatchRegion[id]) {
            this.unwatchRegion[id](); // Unsubscribe from onValue
        }
    }

    watchProducts(notify) {
        console.log(`Watching products`);
         
        let path = ref(this.firebase.db, CatalogFirebaseResources.AllProductSkus());
        this.unwatchProducts = onValue(query(path), (snapshot) => {
            let products = snapshot.val();
            if(products) {
                notify(products);
            }

        }, (error) => {
            console.error(`Watching products`, error);
        });
    }


    watchPrices(notify) {
        console.log(`Watching prices`);
         
        let path = ref(this.firebase.db, CatalogFirebaseResources.AllProductPrices());
        this.unwatchPrices = onValue(query(path), (snapshot) => {
            let prices = snapshot.val();
            if(prices) {
                notify(prices);
            }
            
        }, (error) => {
            console.error(`Watching prices`, error);
        });
    }

    watchPromotions(notify) {
        console.log(`Watching promotions`);
         
        let path = ref(this.firebase.db, CatalogFirebaseResources.AllStorePromotions());
        this.unwatchPrices = onValue(query(path), (snapshot) => {
            let promotions = snapshot.val();
            if(promotions) {
                notify(promotions);
            }
            
        }, (error) => {
            console.error(`Watching promotions`, error);
        });
    }

    async getRegions() {
        let regions = await this.firebase.get(CatalogFirebaseResources.AllRegions());
        return Object.keys(regions); // flatten to match provinces.json list
    }

    async getRegion(region) {
        return this.firebase.get(CatalogFirebaseResources.Region(region));
    }

    async getRegionMetadata(region) {
        return this.firebase.get(CatalogFirebaseResources.RegionMetadata(region));
    }

    async getSubscriptionMetadata(region, subscription) {
        return this.firebase.get(CatalogFirebaseResources.SubscriptionMetadata(region, subscription));
    }

    async getSubscriptionLayerMetadata(region, subscription, layer) {
        return this.firebase.get(CatalogFirebaseResources.SubscriptionLayerMetadata(region, subscription, layer));
    }

    async getResourceMetadata(region, resource) {
        return this.firebase.get(CatalogFirebaseResources.ResourceMetadata(region, resource));
    }

    async getResourceLayerMetadata(region, resource, layer) {
        return this.firebase.get(CatalogFirebaseResources.ResourceLayerMetadata(region, resource, layer));
    }

    async getResourceLayerMapMetadata(region, resource, layer, map) {
        return this.firebase.get(CatalogFirebaseResources.MapMetadata(region, resource, layer, map));
    }

    async getResourceLayerProductMetadata(region, resource, layer, map, product) {
        return this.firebase.get(CatalogFirebaseResources.ProductMetadata(region, resource, layer, map, product));
    }

    async getProductSkus() {
        return this.firebase.get(CatalogFirebaseResources.AllProductSkus());
    }

    async getProductPrices() {
        return this.firebase.get(CatalogFirebaseResources.AllProductPrices());
    }

    async getProductPromotions() {
        return this.firebase.get(CatalogFirebaseResources.AllStorePromotions());
    }


    encodeKeys(obj) {
        var result = {};
        for(let prop in obj){
            let key = this.firebase.encodeKey(prop);
            if(typeof obj[prop] === 'object'){
                result[key] = this.encodeKeys(obj[prop]);
            }
            else{
                result[key] = obj[prop];
            }
    }
        return result;
    }

    async updateRegionMetadata(region, metadata) {
        metadata = this.encodeKeys(metadata);
        return await this.firebase.update(CatalogFirebaseResources.RegionMetadata(region), metadata);
    }

    async updateSubscriptionMetadata(region, subscription, metadata) {
        metadata = this.encodeKeys(metadata);
        return await this.firebase.update(CatalogFirebaseResources.SubscriptionMetadata(region, subscription), metadata);
    }

    async updateSubscriptionLayerMetadata(region, subscription, layer, metadata) {
        metadata = this.encodeKeys(metadata);
        return await this.firebase.update(CatalogFirebaseResources.SubscriptionLayerMetadata(region, subscription, layer), metadata);
    }

    async updateResourceMetadata(region, resource, metadata) {
        metadata = this.encodeKeys(metadata);
        return await this.firebase.update(CatalogFirebaseResources.ResourceMetadata(region, resource), metadata);
    }

    async updateResourceLayerMetadata(region, resource, layer, metadata) {
        metadata = this.encodeKeys(metadata);
        return await this.firebase.update(CatalogFirebaseResources.ResourceLayerMetadata(region, resource, layer), metadata);
    }

    async updateMapMetadata(region, resource, layer, map, metadata) {
        metadata = this.encodeKeys(metadata);
        return await this.firebase.update(CatalogFirebaseResources.MapMetadata(region, resource, layer, map), metadata);
    }

    async updateProductMetadata(region, resource, layer, map, product, metadata) {
        metadata = this.encodeKeys(metadata);
        return await this.firebase.update(CatalogFirebaseResources.ProductMetadata(region, resource, layer, map, product), metadata);
    }

    async setStorePromotions(promotions) {
        return await this.firebase.set(CatalogFirebaseResources.AllStorePromotions(), promotions);
    }
}
