<style lang="scss">
    @import './dashboard.scss';
    @import 'styles/colors.scss';

    .service-probe {

        .latency::after {
            content: '';
            display: inline-block;
            box-sizing: border-box;
            background-color: $validation-weak;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            border: solid 0.5px $mono-dark;
            margin: 0 2px;
        }

        .latency {
            &.fast::after {
                background-color: $validation-good;
            }

            &.exception::after {
                background-color: $validation-bad;
            }            
        }

        .proxy {
            font-style: italic;
            font-size: smaller;
        }
    }

    @keyframes type {
        from{opacity: 1.0}
        to{opacity: 0.0}
    }

</style>

<template>
    <div class="service-probe">
        <a :href="url" class="url">{{urlLabel}}</a>
        <div v-if="proxy" class="proxy">Queried via <a :href="requestUrl"><strong>proxy</strong> function (/proxy?url=)</a> to resolve missing cors headers</div>
        <progress-dots v-if="!isLoaded()"></progress-dots>
        <div v-if="latency" class="latency" :class="{fast: !proxy ? latency < 350 : latency < 550, exception: error != null}"><strong>Latency</strong>: {{latency}}ms</div>
        <div v-for="(prop,i) in properties" :key="i"><strong>{{prop.name}}</strong>: {{prop.value}}</div>
        <slot></slot>
        <json-viewer v-if="data || info" :value="data?data:info" :expand-depth="0"></json-viewer>

        <error-notice v-if="error" :error="error"></error-notice>    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import JsonViewer from 'vue-json-viewer';

    import Environment from 'common/Environment';
    
    const ServiceProbeProps = Vue.extend({
        props: {
            url: {
                type: String,
                required: true
            },

            properties: {
                type: Array,
                required: false
            },

            data: {
                type: Object,
                required: false
            },

            proxy: {
                type: Boolean,
                required: false
            },

            id: {
                type: String,
                required: true
            }
        }
    });


    @Component({
        components: {
            JsonViewer
        }
    })
    export default class ServiceProbe extends ServiceProbeProps {

        error = null;
        info = null;
        latency = null;

        async mounted() {

            if(!this.url) {
                return;
            }

            let start = performance.now();
            try {
                let response = await window.dal.getJson(this.requestUrl, this.proxy);
                if(!response) {
                    throw new Error("Null response");
                }
                this.info = response;
                this.$emit('response', this.id, response);
                
            }catch(error) {
                console.warn(error.stack);
                this.error = error;

            }finally {
                let time = performance.now() - start;
                this.latency = time.toFixed(1);
            }
        }
        isLoaded() {
            return this.latency !== null;
        }

        proxyUrl(url) {
            return this.functionUrl + '/proxy?url=' + url;
        }

        get requestUrl() {
            return this.proxy ? this.proxyUrl(this.url) : this.url;
        }

        get urlLabel() {
            let proxyIndex = this.url.lastIndexOf('/proxy?url=');
            return proxyIndex > 0 ? this.url.substring(proxyIndex) : this.url;
        }

        get config() {
            return Environment.config;
        }

        get functionUrl() {
            return Environment.config.functionURL;
        }
    }

    Vue.component('service-probe', ServiceProbe);

</script>

