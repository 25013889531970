const REGION = {
    METADATA: {
        name: 'PROVINCE_NAME', 
        short: 'PROVINCE_NAME_SHORT',
        project: 'project',
        resource_id: 'SERVER_FOLDER',
        regulations_version: 'regulations_version',
        subscription_version: 'subscription_version',
        url: 'STR_PROVINCE_URL',
    }
};

const SUBSCRIPTION = {
    METADATA: {
        name: 'name', 
        sku_web: 'sku_web',
        sku_ios: 'sku',
        sku_android: 'sku_android',
        url: 'more_info_url',
        header: 'description_header',
        description: 'description',
        generic: 'name_generic'
    }
};

const SUBSCRIPTION_LAYER = {
    METADATA: {
        name: 'name', 
        color: 'color',
    }
};

const RESOURCE = {
    METADATA: {
        name: 'name',
        name_plural: 'name_plural',
        color: 'color',
        // description: 'what_is_description',
    }
};

const RESOURCE_LAYER = {
    METADATA: {
        name: 'name',
        name_plural: 'name_plural',
        color: 'color',
        // description: 'what_is_description',
    },
    BOUNDARYDATA: {
        name: 'Title',
        product: 'Subtitle',
        // north: 'North',
        // east: 'East',
        // south: 'South',
        // west: 'West'
    }
};

const MAP = {
    METADATA: {
        name: 'name',
        description: 'description',
    },
    BOUNDARYDATA: {
        name: 'Title',
        //product: 'Subtitle'
    }
}

const PRODUCT = {
    METADATA: {
        //sku: 'name', // This is a bad assumption; name != sku
        bounds: 'bounds'
    }
};

export default class CatalogPropertyMap {

    static RegionPropertyMap() {
        return REGION.METADATA;
    }

    static SubscriptionPropertyMap() {
        return SUBSCRIPTION.METADATA;
    }

    static SubscriptionLayerPropertyMap() {
        return SUBSCRIPTION_LAYER.METADATA;
    }

    static ResourcePropertyMap() {
        return RESOURCE.METADATA;
    }

    static ResourceLayerPropertyMap() {
        return RESOURCE_LAYER.METADATA;
    }

    static ResourceLayerBoundarydataPropertyMap() {
        return RESOURCE_LAYER.BOUNDARYDATA;
    }

    static ProductBoundarydataPropertyMap() {
        return MAP.BOUNDARYDATA;
    }

    static ExtractRegionFromMetadata(metadata, summary) {
        return this.CopyProperties(REGION.METADATA, metadata, summary);
    }

    static ExtractSubscriptionFromMetadata(metadata, summary) {
        return this.CopyProperties(SUBSCRIPTION.METADATA, metadata, summary);
    }

    static ExtractSubscriptionLayerFromMetadata(metadata, summary) {
        return this.CopyProperties(SUBSCRIPTION_LAYER.METADATA, metadata, summary);
    }

    static ExtractResourceFromMetadata(metadata, summary) {
        return this.CopyProperties(RESOURCE.METADATA, metadata, summary);
    }

    static ExtractResourceLayerFromMetadata(metadata, summary) {
        return this.CopyProperties(RESOURCE_LAYER.METADATA, metadata, summary);
    }

    static ExtractMapFromMetadata(metadata, summary) {
        return this.CopyProperties(MAP.METADATA, metadata, summary);
    }

    static ExtractProductFromMetadata(metadata, summary) {
        return this.CopyProperties(PRODUCT.METADATA, metadata, summary);
    }

    static CopyProperties(properties, metadata, summary) {
        if(!summary) {
            summary = {};
        }
        if(metadata) {
            for(let key in properties) {
                const property = properties[key];
                if(metadata[property]) {
                    summary[key] = metadata[property];
                }
            }
        }
        return summary
    }
}




// const CATALOG_PROPERTY_MAP = {
    
//     REGION: 
//     {
//         METADATA: {
//             name: 'PROVINCE_NAME', 
//             short: 'PROVINCE_NAME_SHORT',
//             regulations_version: 'regulations_version',
//             subscription_version: 'subscription_version',
//             url: 'STR_PROVINCE_URL'
//         }
//     },
//     SUBSCRIPTION: 
//     {
//         METADATA: {
//             name: 'name', 
//             sku: 'sku_web',
//             url: 'more_info_url',
//             // description: 'header_description'
//         }
//     },
//     SUBSCRIPTION_LAYER: 
//     {
//         METADATA: {
//             name: 'name', 
//             color: 'color',
//         }
//     },
//     RESOURCE_LAYER: {
//         METADATA: {
//             name: 'name',
//             name_plural: 'name_plural',
//             color: 'color',
//             // description: 'what_is_description',
//         },
//         BOUNDARYDATA: {
//             name: 'Title',
//             product: 'Subtitle',
//             // north: 'North',
//             // east: 'East',
//             // south: 'South',
//             // west: 'West'
//         }
//     },
//     COUNTY_RESOURCE_LAYER: {
//         METADATA: {

//         },
//         BOUNDARYDATA: {
//             name: 'Title',
//             //product: 'Subtitle'
//         }
//     }
// };