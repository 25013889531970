var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Status Monitor")]),
      _vm._v(" "),
      _c("card-grid", [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Tiles Service  - Counties")]),
            _vm._v(" "),
            _c("cert-probe", { attrs: { url: _vm.tileServer } }),
            _vm._v(" "),
            _c("h3", [_vm._v("Alberta")]),
            _vm._v(" "),
            _c("service-probe", {
              attrs: {
                id: "tilesAb",
                url: _vm.tilesAbCountiesUrl,
                proxy: true,
              },
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Saskatchewan")]),
            _vm._v(" "),
            _c("service-probe", {
              attrs: {
                id: "tilesSk",
                url: _vm.tilesSkCountiesUrl,
                proxy: true,
              },
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Manitoba")]),
            _vm._v(" "),
            _c("service-probe", {
              attrs: {
                id: "tilesMb",
                url: _vm.tilesMbCountiesUrl,
                proxy: true,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("iOS App")]),
            _vm._v(" "),
            _c("service-probe", {
              attrs: {
                id: "ios",
                url: _vm.iosUrl,
                proxy: true,
                properties: _vm.iosProps,
                data: _vm.iosData,
              },
              on: { response: _vm.response },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("h2", [_vm._v("Android App")]),
          _vm._v(" "),
          _c("span", [_c("em", [_vm._v("Not available")])]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Store")]),
            _vm._v(" "),
            _c("cert-probe", { attrs: { url: _vm.storeUrl } }),
            _vm._v(" "),
            _c("service-probe", {
              attrs: {
                id: "store",
                url: _vm.storeUrl + "/manifest.json",
                properties: _vm.storeProps,
              },
              on: { response: _vm.response },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Dashboard")]),
            _vm._v(" "),
            _c("cert-probe", { attrs: { url: _vm.dashboardUrl } }),
            _vm._v(" "),
            _c("service-probe", {
              attrs: {
                id: "dashboard",
                url: _vm.dashboardUrl + "/manifest.json",
                properties: _vm.dashboardProps,
              },
              on: { response: _vm.response },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Web App")]),
            _vm._v(" "),
            _c("cert-probe", { attrs: { url: _vm.appUrl } }),
            _vm._v(" "),
            _c("service-probe", {
              attrs: {
                id: "web",
                url: _vm.appUrl + "/manifest.json",
                properties: _vm.webProps,
              },
              on: { response: _vm.response },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("h2", [_vm._v("Functions")]),
            _vm._v(" "),
            _c("service-probe", {
              attrs: { id: "functions", url: _vm.functionUrl + "/info/status" },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }