var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "service-probe" },
    [
      _c("a", { staticClass: "url", attrs: { href: _vm.url } }, [
        _vm._v(_vm._s(_vm.urlLabel)),
      ]),
      _vm._v(" "),
      _vm.proxy
        ? _c("div", { staticClass: "proxy" }, [
            _vm._v("Queried via "),
            _c("a", { attrs: { href: _vm.requestUrl } }, [
              _c("strong", [_vm._v("proxy")]),
              _vm._v(" function (/proxy?url=)"),
            ]),
            _vm._v(" to resolve missing cors headers"),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoaded() ? _c("progress-dots") : _vm._e(),
      _vm._v(" "),
      _vm.latency
        ? _c(
            "div",
            {
              staticClass: "latency",
              class: {
                fast: !_vm.proxy ? _vm.latency < 350 : _vm.latency < 550,
                exception: _vm.error != null,
              },
            },
            [
              _c("strong", [_vm._v("Latency")]),
              _vm._v(": " + _vm._s(_vm.latency) + "ms"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.properties, function (prop, i) {
        return _c("div", { key: i }, [
          _c("strong", [_vm._v(_vm._s(prop.name))]),
          _vm._v(": " + _vm._s(prop.value)),
        ])
      }),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.data || _vm.info
        ? _c("json-viewer", {
            attrs: { value: _vm.data ? _vm.data : _vm.info, "expand-depth": 0 },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("error-notice", { attrs: { error: _vm.error } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }