var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cert-probe" }, [
    _vm.certificate && !_vm.expired
      ? _c(
          "div",
          {
            staticClass: "expiry",
            class: { far: _vm.days >= 60, near: _vm.days < 30 },
          },
          [
            _c("strong", [_vm._v("Certificate")]),
            _vm._v(": expires in "),
            _c("strong", [_vm._v(_vm._s(_vm.days))]),
            _vm._v(" days"),
          ]
        )
      : _vm.expired
      ? _c("div", { staticClass: "expiry error near" }, [
          _c("strong", [_vm._v("Error")]),
          _vm._v(": " + _vm._s(_vm.certificate?.error)),
        ])
      : _vm.error
      ? _c("div", { staticClass: "expiry warning" }, [
          _c("strong", [_vm._v("Warning")]),
          _vm._v(": " + _vm._s(_vm.error?.details)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }