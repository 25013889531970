var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "header",
        [
          _c("h1", [_vm._v("API Accounts")]),
          _vm._v(" "),
          _c(
            "input-button",
            {
              attrs: { maxlength: 64, placeholder: "New account name" },
              on: {
                click: function ($event) {
                  return _vm.newAccount(_vm.input)
                },
              },
              model: {
                value: _vm.input,
                callback: function ($$v) {
                  _vm.input = $$v
                },
                expression: "input",
              },
            },
            [_vm._v("Create")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.error
        ? _c("error-notice", { attrs: { error: _vm.error } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "card-grid",
        [
          !_vm.accounts && !_vm.error
            ? _c("progress-dots")
            : _vm._l(_vm.accounts, function (account, name) {
                return _c(
                  "div",
                  { key: name, staticClass: "card" },
                  [
                    _c("header", [
                      _c(
                        "h2",
                        {
                          staticClass: "status",
                          class: { enabled: account.active },
                        },
                        [_vm._v(_vm._s(name))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "button-row" }, [
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.deleteAccount(account)
                              },
                            },
                          },
                          [_vm._v("Delete")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.toggleAccount(account)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(account?.active ? "Disable" : "Enable")
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.keys[name]
                      ? _c(
                          "div",
                          [
                            _c("strong", [_vm._v("API Key")]),
                            _vm._v(": "),
                            _c("clip-link", {
                              attrs: { value: _vm.keys[name] },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(account.scope, function (scope) {
                      return _c(
                        "div",
                        {
                          key: scope,
                          staticClass: "list-user",
                          on: {
                            contextmenu: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.showMenu(scope, name)
                            },
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "list-user-link",
                              class: { active: _vm.isMenuVisible(scope, name) },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showMenu(scope, name)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope))]
                          ),
                          _vm._v(" "),
                          _vm.isMenuVisible(scope, name)
                            ? _c("div", { staticClass: "popup-menu" }, [
                                _c("ul", [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeScope(account, scope)
                                        },
                                      },
                                    },
                                    [_c("li", [_vm._v("Remove account scope")])]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-row" },
                      [
                        _c(
                          "input-button",
                          {
                            attrs: { type: "text", placeholder: "New scope" },
                            on: {
                              click: function ($event) {
                                return _vm.addScope(account)
                              },
                            },
                            model: {
                              value: account.input,
                              callback: function ($$v) {
                                _vm.$set(account, "input", $$v)
                              },
                              expression: "account.input",
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }