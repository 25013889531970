<style lang="scss" scoped>
    @import './dashboard.scss';
    @import 'styles/colors.scss';

    .input-button {
        display: flex;
        flex-direction: row;
        margin: 2px 0;

        flex-grow: 1;

        // Hide button unless it has focus
        button {
            
            display: none;
            margin-left: 5px;
            padding-right: 10px;

            a { // For slotted <a> links to be white
                color: white;
            }

            &:active, &:hover, &:focus {
                display: block;
            }
        }

        input[type=text] {
            flex-grow: 1;
        }

        // Show button when input field has focus
        input[type=text]:focus ~ button, input[type=text]:active ~ button {
            display: block;   
        }

    }

</style>

<template>
    <div class="input-button">
        <input type="text" :value="content" :placeholder="placeholder" :maxlength="maxlength" @input="onInputChanged">
        <button @click="onButtonClick" :disabled="this.value == ''"><slot></slot></button>
    </div>
</template>

<script>
    import { Component, Watch, Vue } from 'vue-property-decorator';

    const InputButtonProps = Vue.extend({
        props: {
            value: {
                type: String
            },
            maxlength: {
                type: Number
            },
            placeholder: {
                type: String
            },
            suggestions: {
                type: Array
            },
        }
    });


    @Component
    export default class InputButton extends InputButtonProps {
        
        content = null;

        // Catch external v-model prop update and reflect in data-model
        @Watch('value')
        onValueChanged() {
            this.content = this.value;
        }

        onInputChanged(e) {
            this.content = e.target.value;
            this.$emit('input', this.content); // broadcast to v-model

            let options = [];

            for(let i in this.suggestions) {
                let option = this.suggestions[i];
                if(option.startsWith(this.content)) {
                    options.push(option); 
                }
            }

            //console.log(options);
        }

        onButtonClick() {
            this.$emit('click', this.content);
        }

    }

    Vue.component('input-button', InputButton);

</script>

