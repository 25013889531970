var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-notice" }, [
    _c("header", [
      _c("h2", [
        _vm.error.status
          ? _c("span", [_vm._v(_vm._s(_vm.error.status) + ": ")])
          : _vm._e(),
        _vm._v(_vm._s(_vm.error.message)),
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "dismiss",
          on: {
            click: function ($event) {
              return _vm.dismissError()
            },
          },
        },
        [_c("span", [_vm._v("X")])]
      ),
    ]),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.error.details))]),
    _vm._v(" "),
    _vm.unauthorized
      ? _c("div", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.refresh()
                },
              },
            },
            [_vm._v("Reload to refresh access token")]
          ),
        ])
      : _c("div", [
          _vm._v("\n        Please check the console for details\n    "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }