<style lang="scss" scoped>
    @import './dashboard.scss';

    a, .list-user-link {

        &.active {
            padding: 5px 8px 3px 8px;
            background: $menu-colour;
            color: white;
            font-weight: bold;
            border-radius: $menu-radius $menu-radius 0 0;
        }
    }

</style>

<template>
    <div>
        <header>
            <h1>Access Control</h1>
            <input-button v-model="input" :maxlength="64" @click="newScope()" placeholder="New scope key">Create</input-button>
        </header>
        <error-notice v-if="error" :error="error"></error-notice>
        <card-grid>
            <progress-dots v-if="!scopes.length"></progress-dots>
            <div v-else class="card" v-for="scope in scopes" :key="scope.key">
                <h2>{{scope.key}}</h2>
                <div class="list-user" v-for="user in scope.users" :key="user.uid" @contextmenu.stop.prevent="showMenu(scope, user)">
                    <a class="list-user-link" :class="{active: isMenuVisible(scope, user)}" @click.stop="showMenu(scope, user)">{{user.username}}</a>
                    <div class="popup-menu" v-if="isMenuVisible(scope, user)">
                        <ul>
                            <a :href="'#/user/' + user.uid"><li>View user profile</li></a>
                            <a @click="removeScope(scope, user)"><li>Remove user scope</li></a>
                        </ul>
                    </div>
                </div>
                <div class="add-row">
                    <input-button type="text" placeholder="User UID or name" @click="addScope(scope)" v-model="scope.input">Add</input-button>
                </div>
            </div>

        </card-grid>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    import CardGrid from './CardGrid.vue';

    @Component
    export default class AccessPage extends CardGrid {

        error = null;
        input = '';
        scopes = [];
        profiles = {};
        menu = null;

        created() {
            this.reloadScopes();
        }

        destroyed() {
            if(this.menu) {
                this.dismissMenu();
            }
        }
        
        async reloadScopes() {
            try {
                let userScopes = await this.dal.fetchUserScopes();

                await this.dal.loadUserProfiles(userScopes);

                let scopeIndex = {};

                // Iterate through all profiles and build scope index
                for(let uid in this.dal.profiles) {
                    let profile = this.dal.profiles[uid];

                    // Copy user scopes back into user profiles
                    profile.scope = userScopes[uid];

                    // Build scopes index
                    for(let scopeId in profile.scope) {
                        //let scopeId = profile.scope[i];
                        if(!scopeIndex[scopeId]){
                            scopeIndex[scopeId] = {};
                        }
                        scopeIndex[scopeId][uid] = true;
                    }
                }

                // Map scopes index into view model
                this.scopes = Object.entries(scopeIndex).map((scope) => ({
                    key: scope[0],
                    users: Object.entries(scope[1]).map((user) => ({
                        uid: user[0],
                        username: this.dal.profiles[user[0]].username
                    }))
                }));

                // Sort scopes by scope key
                this.scopes.sort((a, b) => {
                    const aKey = a.key.toUpperCase();
                    const bKey = b.key.toUpperCase();
                    if (aKey < bKey) {
                        return -1;
                    }
                    if (aKey > bKey) {
                        return 1;
                    }
                    return 0;
                });

                // Sort scope users by username
                for(let key in this.scopes) {
                    let users = this.scopes[key].users;
                    users.sort((a, b) => {
                        const aName = a.username.toUpperCase();
                        const bName = b.username.toUpperCase();
                        if (aName < bName) {
                            return -1;
                        }
                        if (aName > bName) {
                            return 1;
                        }
                        return 0;
                    });
                }         

            }catch(error) {
                this.error = error;
            }
        }

        showMenu(scope, user) {

            let key = scope.key + ':' + user.uid;
            if(this.menu === key) {
                this.dismissMenu();
                return;
            }
            
            this.menu = key;
            console.log(`Show menu: ${this.menu}`);
            document.addEventListener('click', this.dismissMenu);
            document.addEventListener('contextmenu', this.dismissMenu);
            return true;
        }

        isMenuVisible(scope, user) {
            let key = scope.key + ':' + user.uid;
            return this.menu === key;
        }

        // isScopeActive(scope) {
        //     return this.menu && this.menu.split(':').pop() === scope.key;
        // }

        dismissMenu(e) {
            this.menu = null;

            console.log(`Dismiss menu`);
            document.removeEventListener('click', this.dismissMenu);
            document.removeEventListener('contextmenu', this.dismissMenu);

            e?.stopPropagation();
        }

        async removeScope(scope, user) {
            try {
                await this.dal.removeUserScope(scope.key, user.uid.trim());
                this.reloadScopes();

            }catch(error) {
                this.error = error;
            }

        }

        async addScope(scope) {
            try {
                await this.dal.addUserScope(scope.key, scope.input.trim());
                this.reloadScopes();

            }catch(error) {
                this.error = error;
            }

        }

        newScope() {

            try {
                let key = this.input.trim();
                if(key.length === 0) {
                    throw new Error(`Unable to create new scope; non-empty key required`);
                }

                for(let i in this.scopes) {
                    let scope = this.scopes[i];
                    if(scope.key === key) {
                        throw new Error(`Unable to create new scope; key '${key}' already exists`);
                    }
                }

                console.log("Creating new scope");

                this.scopes.push({
                    key: key,
                    users: []
                });
                
                this.input = '';

            }catch(error) {
                this.error = error;
            }
        }
    }

    Vue.component('access-page', AccessPage);

</script>

