var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "diff" },
    [
      _c("div", { staticClass: "compare" }, [
        _c(
          "div",
          { staticClass: "viewer" },
          [
            _vm.newTitle ? _c("h4", [_vm._v(_vm._s(_vm.newTitle))]) : _vm._e(),
            _vm._v(" "),
            _c("json-viewer", {
              attrs: { value: _vm.newData, "expand-depth": 0, sort: true },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "viewer" },
          [
            _vm.oldTitle ? _c("h4", [_vm._v(_vm._s(_vm.oldTitle))]) : _vm._e(),
            _vm._v(" "),
            _c("json-viewer", {
              attrs: { value: _vm.oldData, "expand-depth": 0, sort: true },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("h4", [_vm._v("Difference")]),
      _vm._v(" "),
      _c(
        "expandable",
        [
          _c("json-compare", {
            attrs: { newData: _vm.newData, oldData: _vm.oldData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }