<style lang="scss" scoped>
    @import './dashboard.scss';
    @import 'styles/colors.scss';

    .error-notice {
        color: $validation-bad-dark;

        margin: 20px 0;
        padding: 5px;

        border: solid $validation-bad 1px;
        border-radius: 5px;

        span {
            color: $validation-bad-dark;
        }

        .dismiss {

            margin-right: 15px;
            a {
                font-weight: bold;
            }
        }
    }

</style>

<template>
    <div class="error-notice">
        <header>
            <h2><span v-if="error.status">{{error.status}}: </span>{{error.message}}</h2>
            <a class="dismiss" @click="dismissError()"><span>X</span></a>
        </header>
        <span>{{error.details}}</span>
        <div v-if="unauthorized">
            <a @click="refresh()">Reload to refresh access token</a>
        </div>
        <div v-else>
            Please check the console for details
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';

    const ErrorNoticeProps = Vue.extend({
        props: {
            error: {
                type: Error,
                required: true
            },
        }
    });


    @Component
    export default class ErrorNotice extends ErrorNoticeProps {

        get unauthorized() {
            return this.error.status === 401;
        }

        refresh() {
            window.location.reload();
        }

        dismissError() {
            this.$emit('dismiss');
        }
    }

    Vue.component('error-notice', ErrorNotice);

</script>

