import templ8r from '../libs/templ8r';

const METADATA = {
    REGION:             '/product/metadata/{region}/region',
    SUBSCRIPTION:       '/product/metadata/{region}/subscriptions/{subscription}',
    SUBSCRIPTION_LAYER: '/product/metadata/{region}/subscriptions_layers/{subscription}/{layer}',
    RESOURCE:           '/product/metadata/{region}/resources/{resource}',
    RESOURCE_LAYER:     '/product/metadata/{region}/resources_layers/{resource}/{layer}',
    MAP:                '/product/metadata/{region}/maps/{resource}/{layer}/{map}',
    PRODUCT:            '/product/metadata/{region}/products/{resource}/{layer}/{map}/{product}',
};

const INDEX = {
    REGIONS:            '/product/regions/{region}',
}

const CATALOG = {
    REGION:             '/product/catalog/{region}',
    SUBSCRIPTION:       '/product/catalog/{region}/subscriptions/{subscription}',
    SUBSCRIPTION_LAYER: '/product/catalog/{region}/subscriptions/{subscription}/layers/{layer}',
    RESOURCE:           '/product/catalog/{region}/resources/{resource}',
    RESOURCE_LAYER:     '/product/catalog/{region}/resources/{resource}/layers/{layer}',
    MAP:                '/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}',
    PRODUCT:            '/product/catalog/{region}/resources/{resource}/layers/{layer}/maps/{map}/products/{product}',
};

const SKU = {
    PRODUCT:            '/product/skus/{product}',
}

const PRICE = {
    PRODUCT:            '/product/prices/{product}',
}

const STORE = {
    PROMOTIONS:         '/store/promotions',
}

export default class CatalogFirebaseResources {

    static AllRegions() {
        return stripTrailingSlash(templ8r(INDEX.REGIONS, {region: ''}));
    }
    static RegionsRoute() {
        return INDEX.REGIONS;
    }
    static Regions(region) {
        return templ8r(INDEX.REGIONS, {region: region});
    }
    

    static RegionRoute() {
        return CATALOG.REGION;
    }
    static Region(region) {
        return templ8r(CATALOG.REGION, {region: region});
    }
    static RegionMetadataRoute() {
        return METADATA.REGION;
    }
    static RegionMetadata(region) {
        return templ8r(METADATA.REGION, {region: region});
    }


    static SubscriptionRoute() {
        return CATALOG.SUBSCRIPTION;
    }
    static Subscription(region, subscription) {
        return templ8r(CATALOG.SUBSCRIPTION, {region, subscription});
    }
    static SubscriptionMetadataRoute() {
        return METADATA.SUBSCRIPTION;
    }
    static SubscriptionMetadata(region, subscription) {
        return templ8r(METADATA.SUBSCRIPTION, {region, subscription});
    }


    static SubscriptionLayerRoute() {
        return CATALOG.SUBSCRIPTION_LAYER;
    }
    static SubscriptionLayer(region, subscription, layer) {
        return templ8r(CATALOG.SUBSCRIPTION_LAYER, {region, subscription, layer});
    }
    static SubscriptionLayerMetadataRoute() {
        return METADATA.SUBSCRIPTION_LAYER;
    }
    static SubscriptionLayerMetadata(region, subscription, layer) {
        return templ8r(METADATA.SUBSCRIPTION_LAYER, {region, subscription, layer});
    }


    static ResourceRoute() {
        return CATALOG.RESOURCE;
    }
    static Resource(region, resource) {
        return templ8r(CATALOG.RESOURCE, {region: region, resource: resource});
    }
    static ResourceMetadataRoute() {
        return METADATA.RESOURCE;
    }
    static ResourceMetadata(region, resource) {
        return templ8r(METADATA.RESOURCE, {region: region, resource: resource});
    }


    static ResourceLayerRoute() {
        return CATALOG.RESOURCE_LAYER;
    }
    static ResourceLayer(region, resource, layer) {
        return templ8r(CATALOG.RESOURCE_LAYER, {region: region, resource: resource, layer: layer});
    }
    static ResourceLayerMetadataRoute() {
        return METADATA.RESOURCE_LAYER;
    }
    static ResourceLayerMetadata(region, resource, layer) {
        return templ8r(METADATA.RESOURCE_LAYER, {region: region, resource: resource, layer: layer});
    }


    static MapRoute() {
        return CATALOG.MAP;
    }
    static Map(region, resource, layer, map) {
        return templ8r(CATALOG.MAP, {region: region, resource: resource, layer: layer, map: map});
    }
    static MapMetadataRoute() {
        return METADATA.MAP;
    }
    static MapMetadata(region, resource, layer, map) {
        return templ8r(METADATA.MAP, {region: region, resource: resource, layer: layer, map: map});
    }


    static ProductRoute() {
        return CATALOG.PRODUCT;
    }
    static Product(region, resource, layer, map, product) {
        return templ8r(CATALOG.PRODUCT, {region: region, resource: resource, layer: layer, map: map, product: product});
    }
    static ProductMetadataRoute() {
        return METADATA.PRODUCT;
    }
    static ProductMetadata(region, resource, layer, map, product) {
        return templ8r(METADATA.PRODUCT, {region: region, resource: resource, layer: layer, map: map, product: product});
    }


    static AllProductSkus() {
        return stripTrailingSlash(templ8r(SKU.PRODUCT, {product: ''}));
    }
    static ProductSku(product) {
        return templ8r(SKU.PRODUCT, {product: product});
    }


    static AllProductPrices() {
        return stripTrailingSlash(templ8r(PRICE.PRODUCT, {product: ''}));
    }
    static ProductPrice(product) {
        return templ8r(PRICE.PRODUCT, {product: product});
    }


    static AllStorePromotions() {
        return STORE.PROMOTIONS;
    }

}

const stripTrailingSlash = (str) => {
    return str.endsWith('/') ? str.slice(0, -1) : str;
};