<style lang="scss" scoped>

    .form {
        // display: flex;
        margin: 8px;
        
        .form-row {
            display: flex;
            flex-direction: row;

            span {
                margin: 0 4px;
            }

            input[type=text] {
                flex-grow: 1;
                max-width: 256px;
            }
        }

        .form-group {
            display: flex;
            flex-direction: column;
        }
    }
</style>

<template>
    <div>
        <h1>Products</h1>
        <card-grid>
            <div class="card">
                <h2>New Product</h2>
                <p><span>Add a new purchasable product to the web billing system</span></p>
                <form @submit.prevent="submit">
                    <div class="form-group">
                        <label for="category">Type</label>
                        <div class="form-row">
                            <select id="type" name="type" v-model="product.type" required>
                                <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="product">Name</label>
                        <div class="form-row">
                            <input type="text" id="product" name="product" placeholder="Product name" v-model="product.name" required/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="sku">SKU</label>
                        <div class="form-row">
                            <input type="text" id="sku" name="sku" placeholder="Unique product identifier" v-model="product.sku" @change="checkSku" required/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="price">Price</label>
                        <div class="form-row">
                            <input type="number" id="price" name="price" placeholder="default price" v-model="product.price" min="0.00" max="100000.00" step="0.01" @change="checkPrice" required/>
                            <span v-if="product.type === 'Subscription'"> / year</span>
                        </div>
                    </div>

                    <br/>
                    <button type="submit" :disabled="processing"><span>Add {{product.sku}}</span></button>
                </form>

                <div v-if="added.length > 0">
                    <h3>Recently history</h3>
                    <div v-for="product in added" :key="product?.metadata?.sku || product?.name">
                        <!-- <b>{{product.name}}</b> -->
                        <div v-if="!product.error">
                            <a :href="productUrl(product)"><b>{{product.name}}</b>: {{productUrl(product)}}</a>
                        </div>
                        <div v-else>
                            <i>Error: {{product.error}}</i>
                        </div>
                        <json-viewer :value="product" :expand-depth="0"></json-viewer>
                    </div>
                </div>
            </div>

            <div class="card">
                <h2>SKUs</h2>
                <p><span>All product SKUs found in the catalog. SKU properties are extracted from Realtime database product metadata /product/metadata (original source is the set of metadata.json files). Enables transation processing (to lookup province code) and product catalog organization.</span></p>
                <b>/product/skus/</b>
                <json-viewer :value="skus" :expand-depth="0" :sort="true"></json-viewer>
            </div>

            <div class="card">
                <h2>Prices</h2>
                <p><span>All product prices found in the catalog. Product prices are extracted from Firestore database product prices /products/{products}/prices/{prices}. Cache of default product prices.</span></p>
                <b>/product/prices/</b>
                <json-viewer :value="prices" :expand-depth="0" :sort="true"></json-viewer>
            </div>
        </card-grid>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import JsonViewer from 'vue-json-viewer';
    import CardGrid from './CardGrid.vue';


    import CatalogFirebaseProvider from 'common/CatalogFirebaseProvider';

    @Component({
        components: {
            JsonViewer
        }
    })
    export default class ProductsPage extends CardGrid {

        prices = {};
        skus = {};

        provider = null;

        added = [];
        error = false;
        processing = false;
        product = {
            type: '',
            name: '',
            sku: '',
            price: 14.99
        }

        get categories() {
            return ['Product', 'Subscription'];
        }

        mounted() {
            this.product.type = this.categories[0];

            this.provider = new CatalogFirebaseProvider(this.product);

            this.load();
        }

        async load() {
            await this.loadSkus();
            await this.loadPrices();
        }

        async loadSkus() {
            this.skus = await this.provider.getProductSkus();
        }

        async loadPrices() {
            this.prices = await this.provider.getProductPrices();
        }

        productUrl(product) {
            let url = 'https://dashboard.stripe.com/';
            if(product.livemode === false) {
                url += 'test/' 
            }
            url += 'products/'
            return url + product.id;
        }

        checkPrice() {
            try {
                this.product.price = parseFloat(this.product.price).toFixed(2);
            }catch(error) {
                console.error(error);
            }
        }

        checkSku() {
            try {
                this.product.sku = this.product.sku.trim().replaceAll(' ', '_', );

            }catch(error) {
                console.error(error);
            }
        }

        async submit() {
            this.processing = true;
            try {
                let response = await this.dal.createNewProduct(this.product);
                this.added.push(response);
                this.product.name = '';
                this.product.sku = '';

            }catch(error) {
                console.error(error.message);

                this.added.push({
                    ...this.product,
                    error: error.message
                })
                this.error = error.message;

            }finally {
                this.processing = false;
            }

            return false; // stop redirect with form params
        }
    }

    Vue.component('products-page', ProductsPage);

</script>

