var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.catalog
    ? _c("div", { staticClass: "catalog-explorer" }, [
        _c(
          "div",
          { staticClass: "panel" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.catalog?.name))]),
            _vm._v(" "),
            _vm.provider?.firebase
              ? _c("b", [_vm._v("/product/catalog/" + _vm._s(_vm.catalog?.id))])
              : _vm._e(),
            _vm._v(" "),
            _c("json-viewer", {
              attrs: { value: _vm.catalog, "expand-depth": 0, sort: true },
            }),
            _vm._v(" "),
            _c(
              "expandable",
              {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "title large" }, [
                            _vm._v("Region"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3581250298
                ),
              },
              [
                _vm._v(" "),
                _c("div", [
                  _c("strong", [_vm._v("Region ID:")]),
                  _vm._v(" " + _vm._s(_vm.catalog?.id)),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("strong", [_vm._v("Resource ID:")]),
                  _vm._v(" " + _vm._s(_vm.catalog?.resource_id)),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("strong", [_vm._v("Website:")]),
                  _c(
                    "a",
                    { attrs: { href: _vm.catalog?.url, target: "_blank" } },
                    [_vm._v(" " + _vm._s(_vm.catalog?.url))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("strong", [_vm._v("Regulations:")]),
                  _vm._v(" " + _vm._s(_vm.catalog?.regulations_version)),
                ]),
                _vm._v(" "),
                _vm.empty(_vm.metadata?.region)
                  ? _c("button", { on: { click: _vm.loadRegionMetadata } }, [
                      _vm._v("Load region metadata"),
                    ])
                  : _c("div", [
                      _c("div", [_c("strong", [_vm._v("Metadata:")])]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "indent" },
                        [
                          _c(
                            "button",
                            { on: { click: _vm.loadRegionMetadata } },
                            [_vm._v("Refresh region metadata")]
                          ),
                          _vm._v(" "),
                          _c("json-viewer", {
                            attrs: {
                              value: _vm.metadata?.region,
                              "expand-depth": 0,
                              sort: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
              ]
            ),
            _vm._v(" "),
            !_vm.empty(_vm.catalog.subscriptions)
              ? _c(
                  "expandable",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("span", { staticClass: "title large" }, [
                                _vm._v("Subscriptions"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3282968274
                    ),
                  },
                  [
                    _vm._v(" "),
                    _vm._l(
                      _vm.catalog.subscriptions,
                      function (subscription, subscriptionId) {
                        return _c(
                          "expandable",
                          {
                            key: subscriptionId,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "title medium" },
                                        [_vm._v(_vm._s(subscription.name))]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("div", [
                              _c("strong", [_vm._v("Website:")]),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: subscription?.url,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(" " + _vm._s(subscription?.url))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("strong", [_vm._v("SKU:")]),
                              _vm._v(
                                " " +
                                  _vm._s(subscription?.sku_web) +
                                  ", " +
                                  _vm._s(subscription?.sku_ios) +
                                  ", " +
                                  _vm._s(subscription?.sku_android)
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.empty(
                              _vm.metadata?.subscriptions[subscriptionId]
                            )
                              ? _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.loadSubscriptionMetadata(
                                          subscriptionId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Load subscription metadata")]
                                )
                              : _c("div", [
                                  _c("div", [
                                    _c("strong", [_vm._v("Metadata:")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "indent" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadSubscriptionMetadata(
                                                subscriptionId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Refresh subscription metadata"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("json-viewer", {
                                        attrs: {
                                          value:
                                            _vm.metadata?.subscriptions[
                                              subscriptionId
                                            ],
                                          "expand-depth": 0,
                                          sort: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                            _vm._v(" "),
                            subscription?.layers?.length
                              ? _c("span", { staticClass: "title medium" }, [
                                  _vm._v("Subscription Layers"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              subscription?.layers,
                              function (layer, layerId) {
                                return _c(
                                  "expandable",
                                  {
                                    key: layerId,
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "title small" },
                                                [_vm._v(_vm._s(layer.name))]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _vm.empty(
                                      _vm.metadata?.subscriptions_layers[
                                        subscriptionId
                                      ]
                                    ) ||
                                    _vm.empty(
                                      _vm.metadata?.subscriptions_layers[
                                        subscriptionId
                                      ][layerId]
                                    )
                                      ? _c(
                                          "button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.loadSubscriptionLayerMetadata(
                                                  subscriptionId,
                                                  layerId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Load layer metadata")]
                                        )
                                      : _c("div", [
                                          _c("div", [
                                            _c("strong", [_vm._v("Metadata:")]),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "indent" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.loadSubscriptionLayerMetadata(
                                                        subscriptionId,
                                                        layerId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Refresh layer metadata"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("json-viewer", {
                                                attrs: {
                                                  value:
                                                    _vm.metadata
                                                      ?.subscriptions_layers[
                                                      subscriptionId
                                                    ][layerId],
                                                  "expand-depth": 0,
                                                  sort: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.empty(_vm.catalog.resources)
              ? _c(
                  "expandable",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function () {
                            return [
                              _c("span", { staticClass: "title large" }, [
                                _vm._v("Resources"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      673365819
                    ),
                  },
                  [
                    _vm._v(" "),
                    _vm._l(
                      _vm.catalog.resources,
                      function (resource, resourceId) {
                        return _c(
                          "expandable",
                          {
                            key: resourceId,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "title medium" },
                                        [_vm._v(_vm._s(resource.name))]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _vm.empty(_vm.metadata?.resources[resourceId])
                              ? _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.loadResourceMetadata(
                                          resourceId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Load resource metadata")]
                                )
                              : _c("div", [
                                  _c("div", [
                                    _c("strong", [_vm._v("Metadata:")]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "indent" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadResourceMetadata(
                                                resourceId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Refresh resource metadata")]
                                      ),
                                      _vm._v(" "),
                                      _c("json-viewer", {
                                        attrs: {
                                          value:
                                            _vm.metadata?.resources[resourceId],
                                          "expand-depth": 0,
                                          sort: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                            _vm._v(" "),
                            _vm._l(resource.layers, function (layer, layerId) {
                              return _c(
                                "expandable",
                                {
                                  key: layerId,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "title small" },
                                              [_vm._v(_vm._s(layer.name))]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _vm.empty(
                                    _vm.metadata?.resources_layers[resourceId]
                                  ) ||
                                  _vm.empty(
                                    _vm.metadata?.resources_layers[resourceId][
                                      layerId
                                    ]
                                  )
                                    ? _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.loadResourceLayerMetadata(
                                                resourceId,
                                                layerId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Load layer metadata")]
                                      )
                                    : _c("div", [
                                        _c("div", [
                                          _c("strong", [_vm._v("Metadata:")]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "indent" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.loadResourceLayerMetadata(
                                                      resourceId,
                                                      layerId
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Refresh layer metadata")]
                                            ),
                                            _vm._v(" "),
                                            _c("json-viewer", {
                                              attrs: {
                                                value:
                                                  _vm.metadata
                                                    ?.resources_layers[
                                                    resourceId
                                                  ][layerId],
                                                "expand-depth": 0,
                                                sort: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                  _vm._v(" "),
                                  _vm._l(layer.maps, function (map, mapId) {
                                    return _c(
                                      "expandable",
                                      {
                                        key: mapId,
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "title small",
                                                    },
                                                    [_vm._v(_vm._s(map.name))]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _vm._l(
                                          map.products,
                                          function (product, productId) {
                                            return _c(
                                              "expandable",
                                              {
                                                key: productId,
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "header",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "title small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  product?.version
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c("strong", [
                                                    _vm._v("SKU:"),
                                                  ]),
                                                  _vm._v(
                                                    " " + _vm._s(productId)
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }),
                                ],
                                2
                              )
                            }),
                          ],
                          2
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel" },
          [
            _c("h2", [_vm._v("Metadata")]),
            _vm._v(" "),
            _c("button", { on: { click: _vm.loadMetadata } }, [
              _vm._v("Load all metadata"),
            ]),
            _vm._v(" "),
            _vm.hasMergeListener && _vm.hasMetadata
              ? _c("button", { on: { click: _vm.mergeMetadata } }, [
                  _vm._v("Merge loaded metadata"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("json-viewer", {
              attrs: { value: _vm.metadata, "expand-depth": 1, sort: true },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }