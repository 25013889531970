import templ8r from '../libs/templ8r';
import Environment from "./Environment";

const METADATA = {
    REGIONS:            '/res/provinces/provinces.json',
    REGION:             '/res/provinces/{region}/province.json',
    RESOURCE:           '/res/provinces/{region}/{resource}/metadata.json',
    RESOURCE_LAYER:     '/res/provinces/{region}/{resource}/{layer}/metadata.json',
    SUBSCRIPTION:       '/res/provinces/{region}/{subscription}/metadata.json',
    SUBSCRIPTION_LAYER: '/res/provinces/{region}/{subscription}/{layer}/metadata.json',
    COUNTIES:           '/tiles/{region}/counties.json',
    COUNTY:             '/tiles/{region}/{county}/metadata.json',
};

const BOUNDARYDATA = {

    RESOURCE_LAYER: '/getBoundariesFromDatabase.php?&database=res/provinces/{region}/{resource}/{layer}/boundarydata.db&identifyby=1',
    SUBSCRIPTION_LAYER: '/getBoundariesFromDatabase.php?&database=res/provinces/{region}/subscription/{layer}/boundarydata.db&identifyby=2'
}

export default class CatalogHostedResources {

    static TileServer() {
        return Environment.config.tileURL;
    }

    static WebServer() {
        return Environment.config.webAppURL;
    }

    static CopyProperties(properties, metadata, summary) {
        if(!summary) {
            summary = {};
        }
        for(let key in properties) {
            const property = properties[key];
            if(metadata[property]) {
                summary[key] = metadata[property];
            }
        }
        return summary
    }

    static RegionsPath() {
        return METADATA.REGIONS;
    }
    static AllRegions() {
        return this.WebServer() + this.RegionsPath() + this.cacheBuster();
    }

    static RegionPath(region) {
        return templ8r(METADATA.REGION, {region: region});
    }
    static RegionMetadata(region) {
        return this.WebServer() + this.RegionPath(region) + this.cacheBuster();
    }

    static ResourcePath(region, resource) {
        return templ8r(METADATA.RESOURCE, {region: region, resource: resource});
    }
    static ResourceMetadata(region, resource) {
        return this.WebServer() + this.ResourcePath(region, resource) + this.cacheBuster();
    }

    static ResourceLayerPath(region, resource, layer) {
        return templ8r(METADATA.RESOURCE_LAYER, {region: region, resource: resource, layer: layer});
    }
    static ResourceLayerMetadata(region, resource, layer) {
        return this.WebServer() + this.ResourceLayerPath(region, resource, layer) + this.cacheBuster();
    }
    static ResourceLayerBoundarydata(region, resource, layer) {
        return this.WebServer() + templ8r(BOUNDARYDATA.RESOURCE_LAYER, {region: region, resource: resource, layer: layer}) + this.cacheBuster();
    }

    static SubscriptionPath(region, subscription) {
        if (!subscription || subscription === 'pro') {
            subscription = 'subscription'; // default name for generic region subscription
        }
        return templ8r(METADATA.SUBSCRIPTION, {region, subscription});
    }
    static SubscriptionMetadata(region, subscription) {
        return this.WebServer() + this.SubscriptionPath(region, subscription) + this.cacheBuster();
    }
    
    static SubscriptionLayerPath(region, subscription, layer) {
        if (!subscription || subscription === 'pro') {
            subscription = 'subscription'; // default name for generic region subscription
        }
        return templ8r(METADATA.SUBSCRIPTION_LAYER, {region, layer, subscription});
    }
    static SubscriptionLayerMetadata(region, subscription, layer) {
        return this.WebServer() + this.SubscriptionLayerPath(region, subscription, layer) + this.cacheBuster();
    }    
    static SubscriptionLayerBoundarydata(region, layer) {
        return this.WebServer() + templ8r(BOUNDARYDATA.SUBSCRIPTION_LAYER, {region: region, layer: layer}) + this.cacheBuster();
    }

    static CountyPath(region) {
        return templ8r(METADATA.COUNTIES, {region: region});
    }
    static CountyMetadata(region) {
        return this.TileServer() + this.CountyPath(region) + this.cacheBuster();
    }    

    static CountyProductPath(region, county) {
        return templ8r(METADATA.COUNTY, {region: region, county: county});
    }
    static CountyProductMetadata(region, county) {
        return this.TileServer() + this.CountyProductPath(region, county) + this.cacheBuster();
    }


    static cacheBuster() {
        return '?v=' + Date.now();
    }
}