<style lang="scss" scoped>

    img {
        width: 100%;
        object-fit: contain;
    }

    .list-item {
        font-size: 16px;
        font-weight: bold;
        &.selected {
            text-decoration: underline;
        }
        // &.inactive {
        //     text-decoration: line-through;
        // }

    }

    form {
        margin: 8px;
        
        .form-group {
            display: flex;
            flex-direction: column;

            .form-row {
                display: flex;
                flex-direction: row;

                span {
                    margin: 0 4px;
                }

                input[type=text] , textarea {
                    width: 100%;
                    flex-grow: 1;

                    &#code {
                        max-width: 181px;
                    }

                    &#promo {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

</style>

<template>
    <div>
        <h1>Promotions</h1>
        <card-grid>

            <div class="card">
                <h2>Edit</h2>
                <p v-if="selected === null">
                    <span>Select or create new promotion on the right</span>
                </p>
                <form v-else-if="promo" @submit.prevent="save">
                    <div class="form-group">
                        <label for="active">Active</label>
                        <div class="form-row">
                            <input type="checkbox" id="active" v-model="promo.active" @change="changes=true"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="name">Name</label>
                        <div class="form-row">
                            <input type="text" id="name" name="name" placeholder="" v-model="promo.name" @change="changes=true"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="desc">Description</label>
                        <div class="form-row">
                            <textarea type="text" id="desc" name="desc" placeholder="" v-model="promo.description" @change="changes=true"></textarea>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="tagline">Cart tagline</label>
                        <div class="form-row">
                            <input type="text" id="tagline" name="tagline" placeholder="Save 20% at checkout with promo code:" v-model="promo.tagline" @change="changes=true"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="code">Promo code</label>
                        <div class="form-row">
                            <input type="text" id="code" name="code" placeholder="PROMOCODE" v-model="promo.code" @change="changes=true"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="start">Start date</label>
                        <div class="form-row">
                            <date-pick id="start" v-model="promo.start" @change="changes=true"></date-pick>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="end">End date</label>
                        <div class="form-row">
                            <date-pick id="end" v-model="promo.end" @change="changes=true"></date-pick>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="image">Image</label>
                        <div class="form-row">
                            <input id="image" v-if="!promo.image" type="file" @change="upload" accept="image/*" />
                            <div v-else>
                                <button @click="promo.image=null; changes=true">Clear image</button>
                                <a :href="promo.image" target="_blank"><img :src="promo.image"></a>
                            </div>
                            <!-- <div>{{ progress }}</div> -->
                        </div>
                    </div>

                    <!-- <br/> -->
                    <!-- <button type="submit" :disabled="processing"><span>Save changes</span></button> -->
                </form>
            </div>


            <div class="card">
                <h2>Manage</h2>

                <button type="submit" :disabled="processing" v-if="changes" @click="save"><span>Save changes</span></button>

                <p><span>Select from the list to manage store promotions</span></p>
                <div class="list-user list-item" v-for="(promotion, index) in promotions" :key="index" @contextmenu.stop.prevent="showMenu(promotion)" :class="{selected: selected === index}">
                    <a class="list-user-link" @click.stop="showMenu(promotion)">‣{{promotion.name}}</a>
                    <div class="popup-menu" v-if="isMenuVisible(promotion)">
                        <ul>
                            <a @click="selected = index" v-if="selected != index"><li>Edit</li></a>

                            <a @click="swapElements(index, index-1)" v-if="index > 0"><li>Move up</li></a>
                            <a @click="swapElements(index, index+1)" v-if="index < promotions.length-1"><li>Move down</li></a>

                            <a @click="remove(index)"><li>Remove</li></a>
                        </ul>
                    </div>
                </div>
                <div class="add-row">
                    <input-button type="text" placeholder="Unique ID" @click="add(input)" v-model="input">Add</input-button>
                </div>
            </div>


            <div class="card">
                <h2>Firebase</h2>
                <!-- <p><span>Select from the list of promotions to edit </span></p> -->      
                <b>/store/promotions/</b>
                <json-viewer :value="database" :expand-depth="2"></json-viewer>
            </div>

            <div class="card">
                <h2>Banner</h2>
                <promotion-banner :promotions="promotions" @click="clickPromo"></promotion-banner>
            </div>
        </card-grid>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import JsonViewer from 'vue-json-viewer';
    import CardGrid from './CardGrid.vue';

    import { getStorage, ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from "firebase/storage";
    import { v4 as uuid } from 'uuid';
    import DatePick from 'vue-date-pick';
    import 'vue-date-pick/dist/vueDatePick.css';

    import CatalogFirebaseProvider from 'common/CatalogFirebaseProvider';

    @Component({
        components: {
            JsonViewer,
            DatePick
        }
    })
    export default class PromotionsPage extends CardGrid {

        database = [];
        promotions = [];

        provider = null;

        added = [];
        error = false;
        processing = false;
        changes = false;

        input = '';
        menu = null;

        selected = null;
        progress = null;


        get promo() {
            if(this.selected === null) {
                return null;
            }
            return this.promotions[this.selected];
        }

        set promo(value) {
            if(this.selected === null) {
                return;
            }
            this.promotions[this.selected] = value;
        }

        mounted() {
            this.provider = new CatalogFirebaseProvider();

            this.provider.watchPromotions((promotions) => {
                console.log("Promotion list loaded");
                this.promotions = promotions;
                this.database = JSON.parse(JSON.stringify(promotions)); // Clone object
            });
        }


        async load() {
            let promos = await this.provider.getProductPromotions();
            
            if(promos  && promos.length > 0) {
                this.promotions = promos;
            }
            console.log(this.promotions);
        }

        swapElements(index1, index2) {
            this.promotions[index1] = this.promotions.splice(index2, 1, this.promotions[index1])[0];

            this.changes = true;
        };


        createPromotion(name) {
            return {
                id: uuid(),
                name,
                image: null,
                active: false
            }
        }

        add() {
            this.promotions.push(this.createPromotion(this.input));
            this.input = '';

            this.selected = this.promotions.length-1;

            this.changes = true;
        }

        remove(index) {
            this.promotions.splice(index, 1);
            if(this.selected === index) {
                this.selected = null;
            }
            this.changes = true;
        }

        async upload(event) {


            var file = event.target.files[0];
            if(!file) {
                return;
            }

            const storage = getStorage();

            if (location.hostname === "localhost") {
                // Point to the Storage emulator running on localhost.
                connectStorageEmulator(storage, "127.0.0.1", 9199);
            } 

            var metadata = {
                customMetadata: {
                    'promotion': this.promo.id
                }
            };

            // Upload file and metadata to the object 'images/mountains.jpg'
            const storageRef = ref(storage, `store/promotions/${this.promo.id}-${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                }, 
                (error) => {
                    console.error(error);
                }, 
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log('File available at', url);
                        this.promo.image = url;
                        this.changes = true;
                    });
                }
            );
        }

        async save() {
            let obj = JSON.parse(JSON.stringify(this.promotions));
            await this.provider.setStorePromotions(obj);
            this.changes = false;
        }

        showMenu(promotion) {
            if(this.menu === promotion) {
                this.dismissMenu();
                return;
            }

            this.menu = promotion;
            console.log(`Show menu: ${this.menu}`);
            document.addEventListener('click', this.dismissMenu);
            document.addEventListener('contextmenu', this.dismissMenu);
            return true;
        }

        isMenuVisible(promotion) {
            return this.menu === promotion;
        }

        dismissMenu(e) {
            this.menu = null;

            console.log(`Dismiss menu`);
            document.removeEventListener('click', this.dismissMenu);
            document.removeEventListener('contextmenu', this.dismissMenu);

            e?.stopPropagation();
        }

        clickPromo(index) {
            console.log(index);
        }
    }

    Vue.component('promotions-page', PromotionsPage);

</script>

