

const MIME_TYPE_JSON = 'application/json';
const MIME_TYPE_BINARY = 'application/octet-stream';

import Environment from "./Environment";
import NetworkError from "./NetworkError";

export default class DataAccessLayer {
   
    constructor() {
    }

    get apiServer() {
        return Environment.config.functionURL;
    }

    get tileServer() {
        return Environment.config.tileURL;
    }

    get webServer() {
        return Environment.config.webAppURL;
    }

    async getAccessToken() {
        return await window.app.getFirebaseToken();
    }

    async get(query, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'GET',
            headers: {
                'Authorization': 'Firebase ' + await token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            }
        };
        return this.request(query, options);
    }

    async getJson(query, authorized) {
        let options = {
            method: 'GET',
            headers: {
                'Accept': MIME_TYPE_JSON
            }
        };
        if(authorized) {
            let token = await this.getAccessToken();
            options.headers['Authorization'] = 'Firebase ' + token;
        }
        return this.request(query, options);
    }

    async put(query, body, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'PUT',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            },
            body: JSON.stringify(body)
        };
        return this.request(query, options);
    }


    async patch(query, body, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'PATCH',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            },
            body: JSON.stringify(body)
        };
        return this.request(query, options);
    }

    async post(query, body, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'POST',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            },
            body: JSON.stringify(body)
        };
        return this.request(query, options);
    }

    async delete(query, contentType, responseType) {
        let token = await this.getAccessToken();
        let options = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Firebase ' + token,
                'Content-Type': contentType || MIME_TYPE_JSON,
                'Accept': responseType || MIME_TYPE_JSON
            }
        };
        return this.request(query, options);
    }

    async request(query, options) {

        if(!options) {
            options = {};
        }
        if(!options.headers) {
            options.headers = {};
        }

        return new Promise((resolve, reject) => {
            try {
                if(!fetch) {
                    return reject(new NetworkError(500, "No fetch"));
                }

                return fetch(query, options).then(async (response) => {
                    if(!response) {
                        return reject(new NetworkError(500, "Unexpected response"));
                        
                    }else if(!response.ok) {

                        let error = new NetworkError(response.status, response.statusText); 
            
                        let type = response.headers.get('Content-Type');
                        if(type && type.startsWith(MIME_TYPE_JSON)) {
                            let json = await response.json();
                            error.stack = json.stack;
                            error.message = json.error;
                            // if(!error.message || error.message === '') {
                            //     error.message = json.error;
                            // }else {
                            //     error.details = json.error;
                            // }
                        }else {
                            let message = await response.text();
                            if(!error.message || error.message === '') {
                                error.message = message;
                            }else {
                                error.details = message;
                            }
                        }
                        return reject(error);
                    }

                    if(response.body && response.status === 200) {
                        let type = options.headers['Accept'] || response.headers.get('Content-Type');
                        if(type && type.startsWith(MIME_TYPE_JSON)) {
                            return resolve(await response.json());
                        }else if(type && type.startsWith(MIME_TYPE_BINARY)) {
                            return resolve(await response.blob());
                        }else {
                            return resolve(await response.text());
                        }
                    }else {
                        return resolve();
                    }
                    
                }).catch((error) =>{
                    return reject(new NetworkError(500, error.message, error));
                });
                
            }catch(error) {
                return reject(error);
            }
        });
    }
}
