var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expandable" }, [
    _c(
      "div",
      { staticClass: "header", on: { click: _vm.toggle } },
      [_vm._t("header")],
      2
    ),
    _vm._v(" "),
    _c("span", {
      staticClass: "chevron",
      class: { open: _vm.active },
      attrs: { src: "../assets/chevron.svg" },
      on: { click: _vm.toggle },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active,
            expression: "active",
          },
        ],
        staticClass: "content",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }